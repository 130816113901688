import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore3 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h4 >Features of Wild Blue Backup Hosted Exchange Server</h4><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                   <h4 style={{color:"#002249"}}>Collaboration</h4>
                   <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                      <li >Enable others to view your calendars, tasks and contacts</li>                
                      <li > Unrestricted number of contacts and distribution lists can be added free of charge</li>           
                      <li > Unlimited Public Folders, free</li>
                       </ul>
                  

                </div>

                <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Mobility</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                    <li >Access to mailbox via BlackBerry, iPhone, Treo, Windows Mobile and other popular mobile devices</li>                
                    <li > Access to mailbox from anywhere over the internet via Outlook or Outlook Web Access</li>           
                  </ul>
                 </div>


                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Technical Support and Monitoring</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li> Around the clock network monitoring</li>                
                    <li > Quickly and easily manage mailboxes, mobile access, distribution lists and other administrative tasks via any web browser</li>           
                    <li >Daily backups</li>
                    <li >99.9% / 100% uptime SLA (Shared/Dedicated servers)</li>
                    <li >Backup MX Server</li>
                    <li >Tier-4 data center to ensure redundant connectivity, power and physical security</li>
                   </ul>
                 </div>

                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Mail Server Security</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                 <li> All customer data scanned for viruses and spam</li>                
                    <li > Rigorous security management on the servers</li>           
                    <li >Most current server software upgrades installed as soon as they are available</li>
                    
                   </ul>
                 </div>

                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Additional Features</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li>Basic archiving, regulatory compliant archiving and journaling also available</li>                
                    <li > Split Domains for easy migration</li>           
                       <li>Fully licensed copy of Outlook 2007 or Entourage 2008 available free for all Exchange users</li>    
                       <li>Wide variety of storage options available - from hundreds of megabytes to gigabytes</li>  
                       <li>No hardware or software to buy</li>          
                   </ul>
                 </div>






              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 3. Exchange Server Features </li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore3;