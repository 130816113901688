import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faTwitter,
    faBehance,
    faLinkedinIn,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';


class SocialLink extends Component {
    render() { 
        return (
          <ul>
            <li>
              <a href="https://facebook.com" target="__blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/wildbluebackup" target="__blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
             <li>
              <a href="https://linkedin.com" target="__blank">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </li>
            
          </ul>
        );
    }
}
 
export default SocialLink;