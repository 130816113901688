import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';




class CaseDetails extends React.Component {

    render() { 
        return (
          <Layout>
            <div
              className="page-title-area"
              style={{ fontFamily: "montserrat" }}
            >
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">Off-Site Data Backup</h2>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <Link to={"/case-details-2"}>
                              Off-Site Data Backup Features
                            </Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}

            {/*====== CASE DETAILS PART START ======*/}
            <div
              className="case-details-area pt-120 pb-115"
              style={{ fontFamily: "montserrat" }}
            >
              <Container>
                <h3 className="title" style={{ marginTop: "120px" }}>
                  Off-Site Data Backup
                </h3>
                <Row>
                  <Col lg="12">
                    {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
                    <div className="case-details-content mt-50 pb-20">
                      <p style={{ color: "black" }}>
                        Off-site data backup is the latest technology referred
                        by IT professionals worldwide. Ensuring the security of
                        business and personal data is paramount in today's IT
                        world with virtually all work being done on computers.
                        Off-site data backup is the most secure way to store
                        data since it provides the best disaster security with
                        data being backed up off-site so that in the event of a
                        natural disaster, fire, theft, power failure, water
                        damage, etc. data is secure. Data is updated daily at
                        regular intervals and restoration of data is quick and
                        easy. Off-site back up is a no hassle way to ensure the
                        security of a business' livelihood since having computer
                        systems down costs both time and money and usually sends
                        people into panic mode, unable to function properly.
                      </p>
                      <div className="case-details-case mt-25">
                        <img
                          src="assets/images/case-content-thumb.png"
                          alt=""
                          style={{ width: "250px", height: "160px" }}
                        />

                        <p style={{ color: "black" }}>
                          {" "}
                          <h5 className="title">
                            Ensuring Business Continuity
                          </h5>{" "}
                          <br />
                          If you are a sales or operations manager with
                          employees, Off-Site Backup Services are the perfect
                          solution for you to be able to make sure your
                          employees work and data is backed up in case of sudden
                          tragedy, illness theft, or termination. Our online
                          backup service is also compliant with the security
                          requirements of the HIPAA & Sarbanes-Oxley Acts, which
                          mandates that Healthcare & Corporations provide
                          secure, encrypted off-site backup. Whether your
                          back-up is federally mandated or just good common
                          sense, we are experts at ensuring your business
                          continuity!
                        </p>
                      </div>

                      <div className="case-details-case mt-25">
                        <img
                          src="assets/images/case-content-thumb2.png"
                          alt=""
                          style={{ width: "250px", height: "160px" }}
                        />

                        <p style={{ color: "black" }}>
                          {" "}
                          <h5 className="title">Compliant Solutions</h5> <br />
                          Leading organizations in industries such as Financial
                          Services, Insurance, Legal, Healthcare, Engineering,
                          Transportation and Business Services rely on Wild Blue
                          Backup's secure online backup and recovery solutions
                          to become and remain compliant with regulatory
                          requirements with respect to secure archival and
                          management of their data. Wild Blue Backup assists
                          with: Financial Services Companies NASD 3010 and 3110,
                          SEC 17a-3 and 17a-4, GLBA. Health Care HIPAA and
                          Public Law 104-191. Public Companies Sarbanes-Oxley
                          802(a)(1), 802(a)(2) and NASD 3510 and 3520.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>

                {/*image gallery 
           
            <Row className="justify-content-center">
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-1.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-2.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-3.jpg" alt="" />
                </div>
              </Col>
            </Row>*/}

                <Row>
                  <Col lg="12">
                    <div className="case-content-2 pt-40 pb-25">
                      <h5 className="title">Ensuring Your Personal Life</h5>
                      <br />
                      <p style={{ color: "black" }}>
                        You see, even personal computer users need a back-up
                        copy of their data. The "system recovery disk" that
                        comes with your computer will not recover your personal
                        files. What this means is that any work you have used
                        your computer for since the day you bought it, is lost
                        if disaster strikes. This includes: your emails,
                        favorite places, financial records, research, kids
                        pictures, homework, or your home business files. All of
                        this important data can be gone forever if disaster
                        strikes. Whatever your computer uses, your data is
                        important, and needs to have a routinely scheduled
                        back-up copy made. You can do this easily and cost
                        effectively using our Off-Site Backup Services! Not only
                        do we provide you the most cutting edge back-up and data
                        recovery system on the market, but our technical support
                        is unmatched in the industry as well. We are so
                        confident that you will realize just how easy and
                        valuable our service is: we will offer a no questions
                        asked, 30-Day Money Back Guarantee, and give you a free
                        15-day trial period! Available for Microsoft Windows and
                        Servers.
                      </p>
                    </div>

                    {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}

                    <div className="case-next-prev d-flex justify-content-between pt-50">
                      <div className="case-prev">
                        <a
                          href="assets\images\WildBlueBackup_installer.zip"
                          download
                        >
                          <h4
                            className="download"
                            style={{
                              paddingLeft: "280px",
                              textDecorationLine: "underline",
                            }}
                          >
                            Click Here to Download a 15 Day Trial
                          </h4>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Layout>
        );
    }
}
 
export default CaseDetails;