import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

class NewsPart extends React.Component {
  render() {
    return (
      <div className="news-area news-area-2">
        <Container>
          <Row
            className="justify-content-center"
            style={{ fontFamily: "montserrat" }}
          >
            {/* -----------------------------------------------------------off-site data backup */}
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="single-news mt-30">
                <Link to={"/case-details"}>
                  <img
                    src="assets/images/off-site.jpg"
                    alt=""
                    style={{ width: "390px", height: "300px" }}
                  />
                </Link>
                <div className="single-news-overlay">
                  {/* <span>Product</span> */}
                  <p className="title">
                    <Link to={"/case-details"}>Off-Site Data Backup</Link>
                  </p>
                  <Link to={"/case-details"}>
                    <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
              {/* single news */}
            </div>

            {/* -----------------------------------------------------------off-site data backup features */}
            <div
              className="col-lg-4 col-md-6 col-sm-9"
              style={{ fontFamily: "montserrat" }}
            >
              <div className="single-news mt-30">
                <Link to={"/case-details-2"}>
                  <img
                    src="assets/images/data1.jpg"
                    alt=""
                    style={{ width: "390px", height: "300px" }}
                  />
                </Link>
                <div className="single-news-overlay">
                  {/* <span>Product</span> */}
                  <p
                    className="title"
                    style={{ fontSize: "17px", fontWeight: "bold" }}
                  >
                    <Link to={"/case-details-2"}>
                      Off-Site Data Backup Features
                    </Link>
                  </p>
                  <Link to={"/case-details-2"}>
                    <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
              {/* single news */}
            </div>

            {/* -----------------------------------------------------------web hosting------------ */}
            <div
              className="col-lg-4 col-md-6 col-sm-9"
              style={{ fontFamily: "montserrat" }}
            >
              <div className="single-news mt-30">
                <a href="https://www.bluefinhosting.com/" target="_blank">
                  <img
                    src="assets/images/webhosting.jpg"
                    alt=""
                    style={{ width: "390px", height: "300px" }}
                  />
                </a>
                <div className="single-news-overlay">
                  {/* <span>Product</span> */}
                  <p className="title">
                    <a href="https://www.bluefinhosting.com/" target="_blank">
                      Web Hosting
                    </a>
                  </p>
                  <a href="https://www.bluefinhosting.com/" target="_blank">
                    <i className="fal fa-long-arrow-right" />
                  </a>
                </div>
              </div>

              {/*   single news */}
            </div>

            {/* <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="single-news mt-30">
                <img
                  src="assets/images/hosting.jpg"
                  alt=""
                  style={{ width: "390px", height: "300px" }}
                />
                <div className="single-news-overlay">
                  <h6 className="title">
                    <Link to={"/case-details-3"}>
                      Hosted Microsoft Exchange Server
                    </Link>
                  </h6>
                  <Link to={"/case-details-3"}>
                    <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            */}
            {/* -----------------------------------------------------------supported software------------ */}
            <div className="col-lg-4 col-md-6 col-sm-9">
              <div className="single-news mt-30">
                <img
                  src="assets/images/support.jpg"
                  alt=""
                  style={{ width: "390px", height: "300px" }}
                />
                <div className="single-news-overlay">
                  {/*    <span>Product</span> */}
                  <p className="title">
                    <Link to={"/case-details-4"} target="_blank">
                      Supported Software
                    </Link>
                  </p>
                  <Link to={"/case-details-4"}>
                    <i className="fal fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>

            <Col lg="8">
              <div className="case-study-btn text-center mt-40">
                {/*  <Link  className="main-btn" to={"/"}>
                      Load More +
                    </Link>*/}
              </div>
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
    );
  }
}

export default NewsPart;
