import React from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';

class PartialNav extends React.Component {
    render() { 
        return ( 
            <>
            <Nav className="mr-auto">
              <li className="nav-item">
              <Link className="nav-link" to={'/'}>Home</Link>
             {/*  <ul className="sub-menu">
                <li>
                  <Link to={'/'}>Home 1</Link>
                </li>
                <li>
                  <Link to={'/index2'}>Home 2</Link>
                </li>
                <li>
                  <Link to={'/index3'}>Home 3</Link>
                </li>
                <li>
                  <Link to={'/index4'}>Home 4</Link>
                </li>
              </ul>
               sub menu */}
            </li>
            <li className="nav-item">
                  <Link className="nav-link" to={'/about'}>Company</Link>                
            </li>
           
           
           
            <li className="nav-item">
                  <Link className="nav-link"  to={'/case-study'}>Product</Link>
            {/*    <ul className="sub-menu">
                <li>
                  <Link to={'/services'}> Offsite Backup </Link>
                </li>
                <li>
                  <Link to={'/services-details'}>Data Back-up</Link>
                </li>
                <li>
                  <Link to={'/services-details'}>Web Hosting</Link>
                </li>
                <li>
                    <Link to={'/error'}>Hosted Exchange Server</Link>
                  </li>
                  <li>
                    <Link to={'/faq'}>software</Link>
                  </li>
              </ul>
             sub menu */}
            </li>



            <li className="nav-item">
                  <Link className="nav-link"  to={'/Faq'}>Support</Link>
              {/* <ul className="sub-menu">
                  <li>
                    <Link to={'/Faq'} style={{fontSize:"12px"}}>Support with Off-Site Backup</Link>
                  </li>
                  {/* <li>
                    <Link to={'/case-details-5'} style={{fontSize:"12px"}}>Support with Hosted Exchange</Link>
                  </li> *
                  <li>
                    <Link to={'/customer-support'} style={{fontSize:"12px"}}>Customer Support Assistance</Link>
                  </li>
                              
              </ul> */}
              {/* sub menu */}
            </li>
           
           
            <li className="nav-item">
              <Link  className="nav-link" to={'/buy'}>Buy</Link>
             </li>
           
           
           
            <li className="nav-item">
              <Link  className="nav-link" to={'/contact'}>Contact</Link>
            </li>

          </Nav>
            </>
         );
    }
}
 
export default PartialNav;