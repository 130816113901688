import React from 'react';
import Footer from '../../common/footer/index';
import Header from '../../common/header/Header4';
import Banner from '../../componets/banner/Index4';
import FeaturesPart from '../../componets/FeaturesPart/Index';
import ChoosePart from '../../componets/choosePart/Index';
import Portfolio3Part from '../../componets/portfolio/Index3';
import CounterPart from '../../componets/counterPart/Index';
import FaqPart from '../../componets/FaqPart/Index4';
import GetInTouchPart from '../../componets/getInTouchPart/Index';


class Index4 extends React.Component {
     
    render() { 
        return ( 
            <React.Fragment>
                <Header/>
                <Banner/>
                <FeaturesPart/>
                <ChoosePart/>
               {/* <CounterPart/>*/}
                <Footer/>
                 {/*  <FaqPart/><Portfolio3Part/><GetInTouchPart/> */}
            </React.Fragment>
         );
    }
}
 
export default Index4;