import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';

class AbotFaqPart extends React.Component {

    render() { 
        return ( 
          <div
            className="about-faq-area pb-100 bg_cover"
            style={{ backgroundImage: "url(assets/images/faq-bg.jpg)" }}
          >
            <Container>
              <Row>
                <Col lg="6">
                  <div className="about-faq-content">
                    <span>FAQ</span>
                    <h2 className="title">Get Every answer from here.</h2>
                  </div>

                  <div className="faq-accordion-3 mt-30">
                    <Accordion defaultActiveKey="0">
                    {[0, 1,2,3,4,5].map((key) => (
                    <Card
                      key={key}
                      className="card wow fadeInLeft"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header>
                        <Accordion.Toggle 
                          as={Link}
                          variant="link" 
                          eventKey={key}
                          data-wow-duration=".1.2s"
                          data-wow-delay=".2s"
                        >

                          <FontAwesomeIcon icon={faMagic} className="mr-2" />
                          What is an Online Backup Service?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={key}>
                        <Card.Body>
                          <p>
                          An Online Backup Service is an Internet-based service that allows computer users to routinely backup and recover their important data using a secure and trusted server on the Internet.
If you use your computer to create and save valuable data for business or personal reasons, you should make some type of backup of your important data to prevent a total loss in the event of any kind of system failure or human error. Simple data backup can be done by saving your data to multiple locations, copying the data from its original location to removable media, another hard drive, or another computers hard drive on the network.
To make this task easier, specialized programs known as Backup Software exist to aid users in regularly making a backup copy of their data. Since some users are not interested in managing the tasks necessary for maintaining their own backups, a new breed of backup software was created, known as Online Backup Software. Online Backup software is designed to routinely copy your important files to a private, secure location on the Internet by securely transmitting your data over your existing Internet connection.
                         </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}




                  
                      
                    </Accordion>
                  </div>
                  {/* faq accordion */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default AbotFaqPart;