import React from 'react';
import ModalVideo from 'react-modal-video'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Layout from '../layouts';
import { Container, Row, Col } from 'react-bootstrap';

class ContactPage extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }
  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for contact with us!");
    event.preventDefault();
  }

    render() { 
        return (
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">Contact Us</h2>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Contact Us
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}
            <div>
              {/*====== CONTACT DETAILS PART START ======*/}
              <div
                className="contact-details-area pt-90 pb-120"
                style={{ fontFamily: "montserrat" }}
              >
                <Container>
                  <Row>
                    <Col lg="6">
                      <div className="contact-info mr-30 mt-30">
                        <div className="contact-item-1">
                          <div className="contact-info-item text-center">
                            <i className="fal fa-map" />
                            <h5 className="title">Office Location</h5>
                            <p style={{ fontSize: "14px" }}>
                              {" "}
                              5500 Grossmont Center Dr # 440-2423 LA MESA, CA
                              91942
                            </p>
                          </div>

                          <div className="contact-info-item text-center">
                            <i className="fal fa-envelope" />
                            <h5 className="title">Customer Service</h5>
                            <p style={{ fontSize: "16px" }}>
                              1-702-267-6630 <br />
                              Fax - 1-866-295-0135
                            </p>
                          </div>
                        </div>
                        <div className="contact-item-1">
                          <div className="contact-info-item text-center">
                            <i className="fal fa-phone" />
                            <h5 className="title">Contact Sales</h5>
                            <p style={{ fontSize: "17px" }}>
                              {" "}
                              1-702-267-6630
                              <br />
                              Fax - 1-866-295-0135
                              <br />
                              sales@wildbluebackup.com
                            </p>
                          </div>

                          <div className="contact-info-item text-center">
                            <i className="fal fa-globe" />
                            <h5 className="title">Technical Support</h5>
                            <p style={{ fontSize: "14px" }}>
                              support@wildbluebackup.com
                            </p>
                            <p>1-702-267-6630</p>
                          </div>
                        </div>
                      </div>
                      {/* contact info */}
                    </Col>
                    <Col lg="6">
                      <div className="map-area mt-30">
                        <iframe
                          width="100%"
                          height="600"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=5500%20grossmont%20center,%20La%20Mesa,%20CA%2091942+(WildBlue%20Backup)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe>
                      </div>

                    
                      {/* map area */}
                    </Col>
                  </Row>
                  {/* row */}
                </Container>
                {/* container */}
              </div>
              {/*====== CONTACT DETAILS PART ENDS ======*/}

              {/*====== QUOTE PART START ======*/}
              <div
                className="quote-area bg_cover"
                style={{ backgroundImage: "url(assets/images/form-bg.jpg)" }}
              >
                <div className="quote-overlay">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg="6">
                        <div className="section-title-2 text-center pl-25 pr-25">
                          <h2 className="title">GET IN TOUCH</h2>
                          <p>
                            Please fill out the quick form and we will be in
                            touch with lightening speed.
                          </p>
                        </div>
                        {/* section title 2 */}
                      </Col>
                    </Row>
                    {/* row */}
                    <div className="quote-form">
                      <form onSubmit={(e) => this.handleForm(e)}>
                        <Row>
                          <Col lg="6">
                            <div className="input-box mt-30">
                              <input
                                type="text"
                                placeholder="Enter your name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="input-box mt-30">
                              <input
                                type="email"
                                placeholder="Enter your email"
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <div className="input-box text-center mt-30">
                              <textarea
                                name="index"
                                id="index"
                                cols={30}
                                rows={10}
                                placeholder="Enter your subject"
                                defaultValue={""}
                              />
                              <button className="main-btn" type="submit">
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>
                    {/* quote form */}
                  </Container>
                  {/* container */}
                </div>
              </div>
              {/*====== QUOTE PART ENDS ======*/}
            </div>
          </Layout>
        );
    }
}
 
export default ContactPage;