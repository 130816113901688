import React from 'react';
import { Link } from 'react-router-dom';
import './Index.scss';
import { Container, Row, Col } from 'react-bootstrap';

class Index extends React.Component {

    render() { 
        return (
          <div
            className="about-experience-area pb-120"
            style={{ fontFamily: "montserrat" }}
          >
            <Container>
              <p style={{ textAlign: "center", color: "black" }}>
                If I lose My Data Today .... Will I Still Be In Business
                Tomorrow? If My Computer Dies .... Can I Get My Data Back? Can I
                Buy Protection For My Data?
              </p>
              <br />

              <h2 style={{ textAlign: "center" }}>With Wild Blue Backup</h2>
              <br />
              <p style={{ textAlign: "center", color: "#0c59db" }}>
                The Answers Are YES!
              </p>

              <br />
              <br />

              <Row>
                <Col lg="5">
                  <div className="experience-item">
                    <span>MR. LEO HEFNER - MANAGING DIRECTOR</span>
                    <br />
                    <img
                      src="assets/images/MD.jpg"
                      alt=""
                      style={{ height: "500px", paddingTop: "10px" }}
                    />
                  </div>
                  {/* experience item */}
                </Col>
                <Col lg="6" className="offset-lg-1">
                  <div className="experience-item">
                    <h2 style={{ color: "#0c59db" }}>Our Company</h2>
                    <br />
                    <p style={{ color: "black" }}>
                      Wild Blue Backup came to be in late 2005 when a friends
                      (Steve) business was broken into and his computers were
                      stolen. Steve lost all of his bookkeeping records, all of
                      the documents and forms he need to run his business. Steve
                      did what every other small business owner would do, try to
                      recreate his accounts receivable and start again from
                      scratch all of his contracts and documents. Steve
                      struggled like this for a few months before he had to
                      throw in the towel. Sitting on the sidelines and watching
                      a friends business slowly fade away was a sad yet eye
                      opening experience. So here was this successful small
                      business owner with 3 employees who was doing everything
                      right. But after talking about how he backed up his data,
                      it became clear that he started out with the best
                      intentions and backed everything up to a thumb drive
                      everyday, got tedious and left by the wayside. After
                      hearing that I wondered how many other people were in his
                      shoes. So I set out to find out, and after about a month
                      and over a hundred small business owners, I got my answer.
                    </p>
                  </div>
                  {/* experience item */}
                </Col>
              </Row>
              {/*  <Row className="justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".2s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-1.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Design &amp; Development</h5>
                      <p>
                        The functional goal of technical content is to help people use a
                        product.
                      </p>
                      <Link to={"about"}>Read More</Link>
                      <i className="fal fa-laptop-code" />
                    </div>
                  </div>
                  {/* single experience 
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-2.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Technical Support</h5>
                      <p>
                        The functional goal of technical content is to help people use a
                        product.
                      </p>
                      <Link to="/home-1">Read More</Link>
                      <i className="fal fa-headphones-alt" />
                    </div>
                  </div>
                  {/* single experience
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay=".6s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-3.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Digital Marketing</h5>
                      <p>
                        The functional goal of technical content is to help people use a
                        product.
                      </p>
                      <Link to={"/about"}>Read More</Link>
                      <i className="fal fa-analytics" />
                    </div>
                  </div>
                  {/* single experience 
              </Row>
             * row */}
            </Container>
            {/* container */}
          </div>
        );
    }
}
 
export default Index;