import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore5 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h3  >Hosted Email Encryption</h3><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}

<br/>

<b style={{color:"#000"}}>Protect your confidential and highly sensitive company data with Wild Blue Backup secure, easy to use and always accessible policy-based email encryption service.</b><br/><br/>
Email is now the leading corporate communications tool and, as a result, it has become increasingly important to safeguard the data contained in these electronic messages.
Computer usernames and passwords, financial data, medical histories, bank account numbers, legal documents, and other personal information is sent via email every day and ensuring the security of this information from email sender to the recipient is critical in avoiding a breach of data security and maintaining compliance with government regulations.
<br/>Wild Blue Backup highly secure policy-based email encryption service removes the risk of data loss and guarantees compliance with the growing amount of regulations enacted to protect the dissemination of personal information by electronic means.




            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                   <h4 style={{color:"#002249"}}>On-Demand and Policy-Based Email Encryption</h4><br/>
                                   
                              <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                                  <li > Wild Blue Backup web-based email encryption service enables a company administrator to assign individual policies per employee, automatically applying encryption to the newly created emails and preventing users from having to remember to manually encrypt each email they send.</li>                
                                  <li > The email is automatically scanned before it is sent for specific content related to the policies selected.</li>           
                                  <li >   This automated search guarantees that your corporate data policies are adhered to and administered properly each time</li>
                              </ul>
                  
                        </div>

                <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
 
                    <h4 style={{color:"#002249"}}>Content-based policies:</h4><br/>
                    &nbsp;&nbsp;    Increase productivity:
                    <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                        <li >HIPPA: Medical records or other health related information</li>                
                        <li > GLBA: Financial data or bank account information</li>     
                        <li>SSN: Social security numbers</li>      
                        <li>PCI: Credit card data</li>
                      </ul>
                 </div>


                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                          <h4 style={{color:"#002249"}}>Ease Of Administration</h4><br/>
                          &nbsp;&nbsp;Should a user wish to manually encrypt an individual email which would not be included in the automated encryption policies, it can easily be done by including the word [encrypt] in any individual email's subject line.<br/>

Wild Blue Backup web-based email encryption service can be enabled with just a couple of clicks of the mouse<br/>
No technical knowledge is required and the small monthly, per user fee eliminates the high costs normally associated with secure messaging systems.<br/>




                          <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                              <li> <b>Online Policy Management:</b> All encryption policies can be managed from one central, easy to use control panel accessible from any internet-enabled computer.</li>                
                              <li ><b>Automated Encryption Key:</b> Wild Blue Backup email encryption service eliminates the IT involvement normally associated with traditional encryption systems.</li>           
                          </ul><br/>
                            As each email is created, an encryption key is automatically generated.<br/>
The encrypted message is then sent to the recipient and the new encryption key is sent to the Hosted Key Service simultaneously.<br/>
Upon receipt of the email, the authentication key is requested and the recipient is able to decrypt their message.
                 </div>





                          <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}>Ease Of Use:</h4><br/>
                                   
                                    <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                                        <li >This web-based encryption service can be set to run automatically, unnoticed by end users, or manually with end user selection.</li>                
                                        <li > It does not require any new hardware or software installation, server monitoring, or encryption key management. Your technical team can spend their time on more strategic activities</li>     
                                        <li>Content sensitive emails are automatically encrypted and decrypted without end user involvement.</li>      
                                        <li>However, manual email encryption can be done by the end user at any time by adding the word [encrypt] at the beginning of the email's subject line</li>
                                      </ul>
                            </div>



                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}>Low Cost Of Ownership</h4><br/>
                                   
                                    <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                                        <li >Wild Blue Backup email encryption service is subscription-based.</li>                
                                        <li > There is one small monthly fee per user so you pay for only what you need.*</li>     
                                        <li>The service can be purchased for a limited number of users or your entire office - it's up to you.</li>      
                                        <li>No new hardware or software is required and there are no contracts to sign.</li>
                                        <li>Any web browser and internet-enabled computer can be used.</li>
                                        <li>*Wild Blue Backup hosted Exchange email service is required to enable email encryption.</li>
                                        </ul>
                            </div>



              </Col>
            </Row>
&nbsp;
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 5. Email Encryption </li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore5;