import React from 'react';
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class AboutIntroPart extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event  => {
    this.setState({isOpen: true})
    event.preventDefault()
  }

    render() { 

        return (
          <div
            className="about-intro-area pt-120"
            style={{ fontFamily: "montserrat" }}
          >
            <Container>
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId="TdSA7gkVYU0"
                onClose={() => this.setState({ isOpen: false })}
              />
              <Row>
                <Col lg="6">
                  <div className="intro-thumb">
                    <img
                      src="/assets/images/Databackup.jpg"
                      alt=""
                      style={{ height: "400px" }}
                    />
                    {/*   <Link className="video-popup" to="https://bit.ly/2VuPnrx" onClick={this.openModal}>
                      <i className="fas fa-play" />
                    </Link>*/}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="intri-content">
                    <span style={{ color: "#0c59db", fontStyle: "bold" }}>
                      OUR VISION
                    </span>
                    <p style={{ color: "black" }}>
                      WBB's vision is to empower businesses to make Backup &
                      software as a part of their day-to-day business operations
                      to protect their critical data at an affordable price.
                    </p>
                    <br />
                    <span style={{ color: "#0c59db", fontStyle: "bold" }}>
                      OUR MISSION
                    </span>
                    <p style={{ fontStyle: "italic", color: "black" }}>
                      {" "}
                      <b>
                        {" "}
                        "We strive to make the database system in our hands
                        robust and secure, and as such, we will"
                        <br />
                        <br />
                      </b>
                      1. proactively maintain and monitor the database system to
                      prevent any potential issues;
                      <br />
                      2. accurately diagnose and forecast the database system
                      health and capacity to maximize the ROI for the whole
                      system;
                      <br />
                      3. quickly respond to stakeholder's support request(s) to
                      remove any work bottleneck on DBA side and
                      <br />
                      4. persistantly seek innovative ways to improve DBA work
                      efficiency and quality.
                      <br />
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
            <br />
            <br />

            <p
              style={{
                paddingLeft: "70px",
                paddingRight: "70px",
                color: "black",
              }}
            >
              At one time or another, most everyone has suffered a loss of
              valuable information due to some unforeseen event. Virtually
              everyone knows that they should backup their critical data, but in
              reality very few people do. After all, backups take forethought,
              time, equipment, money, and ultimately, dedication. Most people,
              even many small businesses, do not back up their systems – even
              though they know they should. You know you need to back up your
              data, but do you? Here are a few reasons people give for avoiding
              backups:
            </p>
            <br />
            <p>
              <ul
                style={{
                  paddingLeft: "100px",
                  paddingRight: "70px",
                  color: "black",
                }}
              >
                <li>1. “Backups are a hassle”</li>
                <li>2. “Backups are time consuming” </li>
                <li>3. “I’ve never had a problem with data loss before”</li>
                <li>
                  4. “I don’t have adequate storage space available to do
                  backups”{" "}
                </li>
                <li>
                  5. “I have a CD-writer, but I rarely get around to running
                  backups”
                </li>
                <li>
                  6. “I don’t have the knowledge to manage all the equipment
                  necessary to do backups”
                </li>
                <li>
                  7.“When my computer fails, my old backup media won’t be
                  readable by my new computer”
                </li>
                <li>
                  8. “Even if I did regular backups, my data wouldn’t really be
                  secure unless I stored it off site”
                </li>
              </ul>
            </p>
            <br />

            <p
              style={{
                paddingLeft: "70px",
                paddingRight: "70px",
                color: "#0c59db",
                fontSize: "20px",
              }}
            >
              Common Unrealized Statistics About Data Loss:
            </p>
            <br />
            <p>
              <ul
                style={{
                  paddingLeft: "100px",
                  paddingRight: "70px",
                  color: "black",
                }}
              >
                <li>
                  1. 6% of all PC's will suffer an episode of data loss in any
                  given year.
                </li>
                <li>2. 7% of data lost is due to Viruses and Hackers. </li>
                <li>
                  3. 7% of data lost is due to Disasters such as theft, fire,
                  flood, storms or terrorism.
                </li>
                <li>
                  4. 12% of data lost is due to Software Errors like
                  compatibility issues, programming errors and improper
                  application termination
                </li>
                <li>
                  5. 30% of data lost is due to Personal Errors like
                  accidentally overwriting and/or deleting files, disgruntled
                  employees or spilled drinks
                </li>
                <li>
                  6. 44% of data lost is due to hardware failures. Hard Drive
                  Crash, Faulty Tape Drives, Laptop Failures to name a few
                </li>
              </ul>
            </p>
            <br />

            <p
              style={{
                paddingLeft: "70px",
                paddingRight: "70px",
                color: "#0c59db",
                fontSize: "20px",
              }}
            >
              Disaster Recovery Statistics:
            </p>
            <br />
            <p>
              <ul
                style={{
                  paddingLeft: "100px",
                  paddingRight: "70px",
                  color: "black",
                }}
              >
                <li>
                  1. Nearly half of small businesses that lose business-critical
                  data in a disaster do not reopen after the loss.
                </li>
                <li>
                  2. 90% who do reopen, are aout of business within two years.{" "}
                </li>
              </ul>
            </p>
            <br />

            <p
              style={{
                paddingLeft: "70px",
                paddingRight: "70px",
                color: "#0c59db",
                fontSize: "20px",
              }}
            >
              Evaluate Your Companies Data Protection
            </p>
            <br />
            <p>
              <ul
                style={{
                  paddingLeft: "100px",
                  paddingRight: "70px",
                  color: "black",
                }}
              >
                <li>
                  1. If your business were to lose its data, how long would it
                  take to recreate?
                </li>
                <li>
                  2. Could your business survive without its customer records,
                  e-mails, financial data and other documents?{" "}
                </li>
                <li>
                  3. If your business were to lose its data, how long would it
                  take to recreate?
                </li>
              </ul>
            </p>
            <br />
          </div>
        );
    }
}
 
export default AboutIntroPart;