import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { ListGroup } from 'react-bootstrap';

class CaseDetails2 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area" style={{fontFamily:"montserrat"}}>
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 >Customer Support Assistance</h2>
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                     Customer Support
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container><br/><br/><br/><br/><br/><br/>
          <h3 className="title">Customer Support</h3>
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                  <p style={{color:"black"}}>
                  Clicking on the appropriate link below will download an application so a Wild Blue Backup technician, if available, can remotely login to your computer right now.
                  <br/><br/>
                  <p style={{fontSize:"20px",color:"black"}}>If your program logo looks like</p><br/><br/>
                   <img src="assets/images/logoround90x90.jpg" alt="" />
<br/><br/>
Please Call 866-295-0135

Or

If your program logo looks like
<br/><br/>
{/* <img src="assets/images/logowbbd.jpg" alt="" /> */}
<br/><br/>

<Card style={{ width: '38rem',alignSelf:'center',color:'#3159D8',borderColor:'#3159D8',marginLeft:"25%" }}>
      <Card.Body>
              <ListGroup>
                    <ListGroup.Item style={{borderColor:"white"}}>  Please Enter Your Name:	     &emsp; &emsp; &emsp; &emsp; &emsp;  <abbr title="Enter your name"><input type="text" name="name"   style={{borderColor:'#3159D8'}}/></abbr> </ListGroup.Item><br/>
                    <ListGroup.Item style={{borderColor:"white"}}>  Please Enter Your Company Name:	 &emsp; <abbr title="Enter your company name"><input type="text" name="name" style={{borderColor:'#3159D8'}}/></abbr> </ListGroup.Item><br/>
                    <ListGroup.Item style={{borderColor:"white"}}>  Please Enter Your Phone Number:	 &emsp;&nbsp; <abbr title="Enter your phone number "><input type="text" name="name"style={{borderColor:'#3159D8'}}style={{borderColor:'#3159D8'}}/></abbr> </ListGroup.Item><br/>
                    <ListGroup.Item style={{borderColor:"white"}}>  Tech Support Code: &nbsp; &emsp; &emsp; &emsp; &emsp; &emsp;    &emsp; &ensp;<abbr title="Enter the tech support code"><input type="text" name="name"  placeholder="WBB 702.267.6629" style={{borderColor:'#3159D8'}} /></abbr> </ListGroup.Item><br/>
                    <a href="assets\images\Support.zip.zip"  download>     <input type="submit" value="Invite WBBd to login"   style={{marginLeft:'23%',width:'350px', color:'white',backgroundColor:'#3159D8',border:"noborder" }} /></a>
              </ListGroup>
      </Card.Body>
</Card>




 





                  </p>
                   {/*  <div className="case-details-case mt-25">
                   <img src="assets/images/case-content-thumb.jpg" alt="" /> 
                   
                    <p> <h5 className="title">Ensuring Business Continuity</h5> 
                    If you are a sales or operations manager with employees, Off-Site Backup
                     Services are the perfect solution for you to be able to make sure your 
                     employees work and data is backed up in case of sudden tragedy, illness
                      theft, or termination. Our online backup service is also compliant with
                       the security requirements of the HIPAA & Sarbanes-Oxley Acts, which mandates
                        that Healthcare & Corporations provide secure, encrypted off-site backup. 
                        Whether your back-up is federally mandated or just good common sense, we
                         are experts at ensuring your business continuity!
                    </p>
              </div>*/}

                  {/*  <div className="case-details-case mt-25">
                   <img src="assets/images/case-content-thumb.jpg" alt="" /> 
                   
                    <p> <h5 className="title">Compliant Solutions</h5> 
                    Leading organizations in industries such as Financial Services, Insurance,
                    Legal, Healthcare, Engineering, Transportation and Business Services rely
                     on Wild Blue Backup's secure online backup and recovery solutions to become 
                     and remain compliant with regulatory requirements with respect to secure 
                     archival and management of their data. Wild Blue Backup assists with: 
                     Financial Services Companies NASD 3010 and 3110, SEC 17a-3 and 17a-4, GLBA.
                     Health Care HIPAA and Public Law 104-191. Public Companies Sarbanes-Oxley 802(a)(1),
                      802(a)(2) and NASD 3510 and 3520.
                    </p>
                  </div>*/}

                </div>
              </Col>
            </Row>
            
           
           
           
           
           
           {/*image gallery 
           
            <Row className="justify-content-center">
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-1.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-2.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-3.jpg" alt="" />
                </div>
              </Col>
            </Row>*/}
        
                 
                  
           
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default CaseDetails2;