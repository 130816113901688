import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col,  } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class CaseDetails2 extends React.Component {

    render() { 
        return (
          <Layout>
            <div
              className="page-title-area"
              style={{ fontFamily: "montserrat" }}
            >
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">Off-Site Data Backup Features</h2>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <Link to={"/case-details"}>
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              
                              Off-Site Data Backup
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            
                            </Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}

            {/*====== CASE DETAILS PART START ======*/}
            <div
              className="case-details-area pt-120 pb-115"
              style={{ fontFamily: "montserrat" }}
            >
              <Container>
                <h3 className="title" style={{ marginTop: "120px" }}>
                  Off-Site Data Features
                </h3>
                <Row>
                  <Col lg="12">
                    {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
                    {/* */}{" "}
                    <div
                      className="case-details-content mt-50 pb-20"
                      style={{ fontFamily: "montserrat" }}
                    >
                      <p style={{ color: "black" }}>
                        End-users simply download and install the client
                        software, and their computers are ready to backup and
                        restore data securely across the Internet to a secure
                        server. The Getting Started Wizard will automatically
                        appear if any of the critical fields have been left
                        blank when setup was run. This wizard walks the user
                        through the steps of connecting to the backup server,
                        configuring backup sets, creating a backup schedule and
                        setting a secret encryption key. FastBIT II Incremental
                        Backups FastBIT technology can accurately extract only
                        the changes that you've made to a file since your last
                        backup. This efficiency is achieved by using the
                        powerful FastBIT difference engine to identify and
                        extract the binary-level differences on two versions of
                        any file.
                      </p>
                      {/*  <div className="case-details-case mt-25">
                   <img src="assets/images/case-content-thumb.jpg" alt="" /> 
                   
                    <p> <h5 className="title">Ensuring Business Continuity</h5> 
                    If you are a sales or operations manager with employees, Off-Site Backup
                     Services are the perfect solution for you to be able to make sure your 
                     employees work and data is backed up in case of sudden tragedy, illness
                      theft, or termination. Our online backup service is also compliant with
                       the security requirements of the HIPAA & Sarbanes-Oxley Acts, which mandates
                        that Healthcare & Corporations provide secure, encrypted off-site backup. 
                        Whether your back-up is federally mandated or just good common sense, we
                         are experts at ensuring your business continuity!
                    </p>
              </div>*/}

                      {/*  <div className="case-details-case mt-25">
                   <img src="assets/images/case-content-thumb.jpg" alt="" /> 
                   
                    <p> <h5 className="title">Compliant Solutions</h5> 
                    Leading organizations in industries such as Financial Services, Insurance,
                    Legal, Healthcare, Engineering, Transportation and Business Services rely
                     on Wild Blue Backup's secure online backup and recovery solutions to become 
                     and remain compliant with regulatory requirements with respect to secure 
                     archival and management of their data. Wild Blue Backup assists with: 
                     Financial Services Companies NASD 3010 and 3110, SEC 17a-3 and 17a-4, GLBA.
                     Health Care HIPAA and Public Law 104-191. Public Companies Sarbanes-Oxley 802(a)(1),
                      802(a)(2) and NASD 3510 and 3520.
                    </p>
                  </div>*/}
                    </div>
                  </Col>
                </Row>

                {/*image gallery 
           
            <Row className="justify-content-center">
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-1.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-2.jpg" alt="" />
                </div>
              </Col>
              <Col lg="4" md="4" sm="10">
                <div className="case-details-item mt-30">
                  <img src="assets/images/case-details-item-3.jpg" alt="" />
                </div>
              </Col>
            </Row>*/}

                <div className="case-prev"></div>

                <a href="assets\images\WildBlueBackup_installer.zip" download>
                  <h4
                    className="title"
                    style={{ color: "red", paddingLeft: "380px" }}
                  >
                    Click Here to Download a 15 Day Trial
                  </h4>
                </a>

                <Row>
                  <Col lg="12">
                    <div className="case-content-2 pt-40 pb-25">
                      <h5 className="title">Ensuring Your Personal Life -</h5>
                      <br />
                      <p style={{ color: "black", fontSize: "12px" }}>
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Microsoft SQL and Exchange Server Plug{" "}
                        </b>
                        -insMicrosoft SQL and Exchange Server Plug-ins Microsoft
                        SQL and Exchange Server Plug-ins - These client-based
                        plug-ins are the ideal way to protect your Microsoft
                        database and messaging systems, even while these
                        important assets are being used 24/7. Supports MSDE/SQL,
                        Exchange Server!
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Advanced Encryption
                        </b>{" "}
                        - Wild Blue Backup effectively secures all your data in
                        an encrypted format and all communications between the
                        client and server are encrypted with the 128 Bit
                        Advanced Encryption Standard (AES) encryption algorithm
                        (previous encryption algorithms continue to be
                        supported).
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Full Speed Backups
                        </b>{" "}
                        - Wild Blue Backup NEVER throttles your bandwidth so you
                        can alway backup and restore your data as fast as your
                        technology will allow.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Open File Backup
                        </b>{" "}
                        - Leveraging Microsoft's latest Volume Shadow-Copy
                        Service (VSS) framework, Wild Blue has incorporated
                        mainstream functionality in its Wild Blue Backup
                        software to permit consistent backup of open files
                        without the need for additional applications or
                        plug-ins. Works with Windows 10.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Disk Images
                        </b>{" "}
                        - The Wild Blue Backup software generates Windows Disk
                        images, System State backups, and Bare Metal Recovery
                        media or discs, for instant restores
                        <br />.
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Silent Mode
                        </b>{" "}
                        - The Wild Blue Backup now supports a Silent mode that
                        will allow the program to run silently in the background
                        without displaying any Windows or Task Bar icons.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Restore Files From Multiple Dates / Multiple Version
                          Restoration
                        </b>{" "}
                        - All users have instant access to restore any version
                        of their data files. Although each incremental backup
                        contains the small changed portions of the file, the
                        server software has the capability of recovering any
                        version of a file from the initial backup to the last
                        incremental backup and EVERY version in between.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Restore Files Multiple Ways
                        </b>{" "}
                        - With Wild Blue Backup, you can restore your data from
                        the client that is installed on the computer being
                        backed up, On-Line at wbb1.wildbluebackup.com or if you
                        need us to, we can mail you your data on DVD, Flash
                        Drive or Hard Drive.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Automatic Online Updates/Upgrades
                        </b>{" "}
                        - All version upgrades are distributed to all end-users
                        automatically after completing a backup. Upgrades happen
                        seamlessly without any user intervention required.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Event Manager
                        </b>{" "}
                        - If a scheduled backup is missed, the next time the
                        computer is started the Event Manager will display the
                        missed event and allow the user to immediately perform
                        the missed event. If a backup fails to complete
                        successfully, the Event Manager will try the backup
                        again when the computer or software is restarted. If a
                        backup fails due to a network problem, the Event Manager
                        will prompt the user to complete the backup at a later
                        date.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          E-mail Notification
                        </b>{" "}
                        - The client software can be configured to send an
                        e-mail to any user(s) upon any successful backup or a
                        backup with errors or warnings. A summary of the attempt
                        is sent in the body of the message and the complete log
                        file may be attached as an option.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Backup Sets
                        </b>{" "}
                        - Wild Blue Backup the ability to create an unlimited
                        number of backup sets. Since backup sets can be
                        scheduled to run independently, a user can configure
                        multiple backup sets to run at different times. For
                        example, a backup set containing all data files can be
                        configured to run at the end of each week and another
                        backup set containing a single database file can be
                        configured to run multiple times per day. All file
                        versions will still be immediately available for the
                        user to restore.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          File Filters
                        </b>{" "}
                        - Including or excluding files from the backup selection
                        is possible with set filters that can be created
                        manually or with an easy-to-use filter wizard. Filters
                        can be created to include all data files on a users
                        system to avoid hunting through a hard drive for all of
                        the data files. Filters can easily be shared with any
                        other users.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Enhanced Scheduling
                        </b>{" "}
                        - The scheduling capabilities allow users to configure
                        backups at any time, multiple times per day or even
                        before Windows Shut Down. Additionally, any third party
                        scheduling application can be used to run the backup
                        application by scheduling simple command line calls to
                        run an automated backup.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          System Tray Control
                        </b>{" "}
                        - Upon installation, a small application is configured
                        to run in your Windows System Tray. (The Windows System
                        Tray is located at the bottom right-hand corner of your
                        computer screen.) This application monitors the
                        scheduling process and give the end user quick access to
                        a number of useful functions, including: - These
                        client-based plug-ins are the ideal way to protect your
                        Microsoft database and messaging systems, even while
                        these important assets are being used 24/7. Supports
                        MSDE/SQL, Exchange Server!
                        <br />
                        <ul
                          style={{
                            paddingLeft: "45px",
                            color: "#black",
                            listStyleType: "upper-roman",
                          }}
                        >
                          <li>
                            - Backup and Close - Run a backup and then close the
                            application when finished.{" "}
                          </li>
                          <li>
                            - Backup and Logoff - Run a backup and logoff the
                            system when finished.{" "}
                          </li>
                          <li>
                            - Backup and Shutdown - Run a backup and shutdown
                            the system when finished.{" "}
                          </li>
                          <li>
                            - Backup and Restart - Run a backup and restart the
                            system when finished.{" "}
                          </li>
                          <li>
                            - Perform Full Backup - Perform full backup now.
                          </li>
                          <li>- View Backup Sets</li>
                          <li>- View Backup Schedules</li>
                          <li>- View Latest Backup Log</li>
                          <li>
                            - Tools - Customize - configuration of 3rd party
                            tools
                          </li>
                          <li>
                            - Options - General - General configuration options.
                          </li>
                          <li>
                            - Options - Backup - Backup configuration options.
                          </li>
                          <li>
                            - Options - Restore - Restore configuration options.
                          </li>
                        </ul>
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          {" "}
                          Log File Review and Printing
                        </b>{" "}
                        - All backup and restore transactions are thoroughly
                        logged on the client and the server applications. The
                        user can quickly review and print the client logs from
                        the client application. The server maintains a Master
                        Log of all general user activity and records detailed
                        information for each user to help track usage and aid in
                        technical support.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Configurable 3rd Party Tools
                        </b>{" "}
                        - Wild Blue Backup supports the ability to run any 3rd
                        party application(s) before or after a backup or restore
                        action. For instance Wild Blue Backup can be configured
                        to run a virus scanner before the backup and run a
                        defragmentation utility after the backup. A virus
                        scanner can also be configured to run after every
                        restore process.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          CPU Utilization
                        </b>{" "}
                        - Since the FastBIT technology leverages the CPU power
                        to get the great file reductions, it can be taxing on
                        the processor. Since some backups are done while the
                        user is working on the system and some backups are done
                        after hours when the user is away, we allow the CPU
                        utilization to be configured. Users can give the backup
                        application all the processing power it needs, or make
                        the backup application subordinate to other tasks.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          Customizable Toolbars
                        </b>{" "}
                        - Wild Blue Bckup supplies useful toolbars for users.
                        The toolbars can be customized and displayed based on
                        the users individual preference to quickly access
                        commonly used functions.
                        <br />
                        <b style={{ fontSize: "14px", color: "#002249" }}>
                          International Languages
                        </b>{" "}
                        - Wild Blue Backup has been engineered to quickly accept
                        translations to several languages including Basque,
                        Catalan, Dutch, English, French, Galician, German,
                        French and Spanish. Additional languages will be
                        available in the future. Please contact us if you would
                        like a localized version for a new language.
                        <br />
                      </p>
                    </div>

                    {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </Layout>
        );
    }
}
 
export default CaseDetails2;