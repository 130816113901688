import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class ServicesItemPart extends React.Component {

    render() { 
        return ( 
          <div className="services-item-area pt-90 pb-120">
            <Container>
              <Row className="justify-content-center">
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-1.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-eye-dropper" />
                      <h4 className="title" style={{fontSize:"18px"}}>Ensuring Business Continuity </h4>
                      <p>
                      If you are a sales or operations manager with employees, 
                      Off-Site Backup Services are the perfect solution for you to
                       be able to make sure your employees work and data is backed up
                        in case of sudden tragedy, illness, theft, or termination. 
                        Our online backup service is also compliant with the security 
                        requirements of the HIPAA & Sarbanes-Oxley Acts, which mandates
                         that Healthcare & Corporations provide secure, encrypted off-site backup
                      . Whether your back-up is federally mandated or just good common sense, we are experts at ensuring your business continuity!
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-2.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-gavel" />
                      <h4 className="title">Compliant Solutions</h4>
                      <p>
                      Leading organizations in industries such as Financial Services, Insurance, Legal, Healthcare, Engineering, Transportation and Business Services rely on Wild Blue Backup's secure online backup and recovery solutions to become and remain compliant with regulatory requirements with respect to secure archival and management of their data. Wild Blue Backup assists with: Financial Services Companies NASD 3010 and 3110, SEC 17a-3 and 17a-4, GLBA. Health Care HIPAA and Public Law 104-191. Public Companies Sarbanes-Oxley 802(a)(1), 802(a)(2) and NASD 3510 and 3520.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
                <Col lg="4" md="6" sm="8">
                  <div className="single-services-item mt-30">
                    <img src="assets/images/services-thumb-3.jpg" alt="" />
                    <div className="services-overlay">
                      <i className="fal fa-map-marked-alt" />
                      <h4 className="title" style={{fontSize:"18px"}}>Ensuring Your Personal Life</h4>
                      <p>
                      You see, even personal computer users need a back-up copy of their data. The "system recovery disk" that comes with your computer will not recover your personal files. What this means is that any work you have used your computer for since the day you bought it, is lost if disaster strikes. This includes: your emails, favorite places, financial records, research, kids pictures, homework, or your home business files. All of this important data can be gone forever if disaster strikes.
                      </p>
                    </div>
                  </div>
                  {/* single services */}
                </Col>
              
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default ServicesItemPart;