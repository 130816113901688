import React from 'react';
import ServicesItemPart from '../componets/servicesItemPart/Index';
import { Link } from 'react-router-dom';
import Layout from '../layouts';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';

class FaqPage extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.handleForm = this.handleForm.bind(this);
}
  handleForm(e){
    e.preventDefault();
  }

  // componentDidMount() {
	// 	setTimeout(function () {
	// 		document.querySelector(".preloader").style = "display: none";
	// 	}, 5000);
  // }


    render() { 
        return (
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h5
                        className="title"
                        style={{ fontSize: "36px", paddingTop: "25px" }}
                      >
                        Support with Off-Site
                      </h5>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            FAQ
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}

            <div>
              {/*====== ABOT FAQ PART START ======================================================*/}
              <div className="about-faq-area pb-100 gray-bg">
                <Container>
                  <Row className="align-items-center">
                    <div className="col-lg-6 order-2 order-lg-1">
                      {/** <div className="faq-video-thumb">
                    <img src="assets/images/faq1.jpg" alt="" height="100%" width="100%"/>
                     <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"} >
                      <i className="fas fa-play" />
                    </Link>*/}
                      <div className="faq-accordion-3 mt-30">
                        <Accordion defaultActiveKey="">
                          <Card style={{ color: "black" }}>
                            <Card.Header
                              style={{ height: "70px", color: "black" }}
                            >
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="0"
                              >
                                <i className="fal fa-magic" />
                                What is an Online Backup Service
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  An Online Backup Service is an Internet-based
                                  service that allows computer users to
                                  routinely backup and recover their important
                                  data using a secure and trusted server on the
                                  Internet. If you use your computer to create
                                  and save valuable data for business or
                                  personal reasons, you should make some type of
                                  backup of your important data to prevent a
                                  total loss in the event of any kind of system
                                  failure or human error. Simple data backup can
                                  be done by saving your data to multiple
                                  locations, copying the data from its original
                                  location to removable media, another hard
                                  drive, or another computers hard drive on the
                                  network. To make this task easier, specialized
                                  programs known as Backup Software exist to aid
                                  users in regularly making a backup copy of
                                  their data. Since some users are not
                                  interested in managing the tasks necessary for
                                  maintaining their own backups, a new breed of
                                  backup software was created, known as Online
                                  Backup Software. Online Backup software is
                                  designed to routinely copy your important
                                  files to a private, secure location on the
                                  Internet by securely transmitting your data
                                  over your existing Internet connection.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="1"
                              >
                                <i className="fal fa-magic" />
                                Can we try the software before we buy it?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  Sure! You can download a 15 day trial of our
                                  online backup service, or you can send an
                                  e-mail to our sales department at
                                  sales@wildblue702.com to discuss which of our
                                  packages are right for you.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header style={{ height: "70px" }}>
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="2"
                              >
                                <i className="fal fa-magic" />
                                Is it possible to download a demo version?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  The exchange and sql plugins are actually
                                  included in the demo client when you download
                                  the client. There is nothing more to install
                                  on the client machine besides the Wild Blue
                                  Backup software. The plugins are initially
                                  installed with a 30 day demo license.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header id="headingFive">
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="4"
                              >
                                <i className="fal fa-magic" />
                                What files should you select?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  In general, you can avoid backing up any
                                  programs that you can reinstall from a
                                  purchased CD, such as Microsoft Office (Word,
                                  Excel, etc.). Why pay for storage for "stuff"
                                  you can easily re-install? Try to focus your
                                  selection on things like your "My Documents",
                                  "My Pictures" or "My Music" folders and data
                                  files of your important programs like
                                  accounting, income tax, contact management,
                                  etc. Your online backup software comes with
                                  predefined filters, which allow you to
                                  automatically select categories of files
                                  without knowing where all the files are
                                  located. See the software's Help section on
                                  Filters to learn how they can be used.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="3"
                              >
                                <i className="fal fa-magic" />
                                What is the Personal Recovery CD?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  The Personal Recovery CD is a service that
                                  offers copies all of your data from our
                                  servers onto CD(s). This allows you to restore
                                  the archive of your data from a CD without
                                  requiring an Internet connection. This is
                                  great for keeping long-term archives of your
                                  data or restoring large amounts of data
                                  without connecting over a slow Internet
                                  connection
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}
                        </Accordion>
                      </div>
                    </div>
                    <div className="about-faq-content">
                      <span>FAQ</span>
                      <h5 className="title" style={{ fontSize: "36px" }}>
                        WILD BLUE BACKUP ONLINE SERVICES BILLING FAQ
                      </h5>
                    </div>

                    <div className="col-lg-6 order-1 order-lg-2">
                      {/** */}
                      <div className="faq-accordion-3 mt-30">
                        <Accordion defaultActiveKey="">
                          <Card>
                            <Card.Header>
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="0"
                              >
                                What happens if I exceed my quota?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  We do not limit your bandwidth or space
                                  avaiable so this is not quote to exceed{" "}
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header id="heading2">
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="1"
                              >
                                How can I get a receipt for the services billed
                                to credit card?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  Your reciept will be mailed to you at the end
                                  of each billing cycle.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header id="heading3">
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="2"
                              >
                                How do I change my billing information?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  Send us an e-mail at billing@wildblue702.com
                                  or call us at (702) 267-6630.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header id="heading4">
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="3"
                              >
                                How do I cancel my online backup service?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  We never like to see anyone cancel their
                                  online service, but in the event you must
                                  cancel your service, please send us an e-mail
                                  at sales@wildblue702.com and please provide
                                  your complete contact information including
                                  address, phone & E-mail along with your
                                  Username and Password for the online backup
                                  service. You may also call us at (702) 267 -
                                  6630 and speak with someone regarding the
                                  cancellation of your online backup service.
                                  Once an account is canceled, the data may be
                                  permanently deleted, so if you want a copy of
                                  your backups, please consider a Personal
                                  Recovery CD prior to cancellation.
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}

                          <Card>
                            <Card.Header id="heading5">
                              <Accordion.Toggle
                                as={Link}
                                variant="link"
                                eventKey="4"
                              >
                                How do you calculate my billing?
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body>
                                <p style={{ color: "black" }}>
                                  Monthly Billing Customers - On a monthly
                                  basis, we will compare your pricing plan to
                                  your actual storage usage and determine your
                                  costs for that month. If your storage usage is
                                  below that amount of storage provided in your
                                  base plan, no additional charges will be
                                  processed. If your storage usage is greater
                                  than your base plan amount, an additional
                                  charge is calculated based on the additional
                                  increments of storage space needed to
                                  accommodate for your storage usage for the
                                  month. A new calculation is performed every
                                  month to determine the billing total for that
                                  month.
                                  <br />
                                  <br />
                                  Monthly Billing Example:
                                  <br />
                                  Base Plan: $9.95 per month for up to 500 MB of
                                  storage;
                                  <br />
                                  Additional Storage: $5.95 per month for each
                                  additional 250 MB
                                  <br />
                                  Scenario 1 - Actual Storage Usage = 425 MB
                                  <br />
                                  (Cost=$9.95; 425 MB is less than the allowed
                                  500 MB for the base plan)
                                  <br />
                                  Scenario 2 - Actual Storage Usage = 895 MB
                                  <br />
                                  (Cost=$21.85; $9.95 for 500 MB, 2 x $5.95 for
                                  each additional increment of 250 MB)
                                  <br />
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                          {/* card */}
                        </Accordion>
                      </div>
                      {/* faq accordion */}
                    </div>
                  </Row>
                  {/* row */}
                </Container>
                {/* container */}
              </div>
              {/*====== ABOT FAQ PART ENDS ======*/}
            </div>
          </Layout>
        );
    }
}
 
export default FaqPage;