import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore9 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h3>Premier Tier-4 Data Center</h3><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}

<br/>
                          <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                            At Wild Blue Backup, we understand the importance of securing our customers' data at a best-in-class data center.<br/>
                                            The facility is an Internap data center, which guarantees the following:<br/>
                                                            
                                            <br/><h4 style={{color:"#002249"}}>Features:</h4><br/>
                                        
                                            <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                                              <li >A maximum 45 millisecond latency guaranteed</li>                
                                              <li >0.3% packet loss max guarantee</li>           
                                              <li >0.5 millisecond jitter max guarantee</li>
                                          </ul>
                            </div>



                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                    <h4 style={{color:"#002249"}}>Features:</h4><br/>
                                  
                                    <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                                              <li >Best-in-class Tier-4 data center</li>                
                                              <li >40,000 square foot unmarked building</li>           
                                              <li >Biometric security</li>
                                              <li >Numerous levels of hardened physical security</li>                
                                              <li >Numerous levels of electronic card control access</li>           
                                              <li >24x7x365 security presence</li>
                                              <li >Closed-circuit television surveillance with digital storage</li>                
                                              <li >4 redundant network backbones:</li>        

                                                        <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                                                            <li >Sprint - GigE link</li>                
                                                            <li >MCI/UUNET - 600Mbps</li>           
                                                            <li >Level3 - GigE link</li>
                                                            <li>SAVVIS - GigE link</li>
                                                       </ul>


                                              <li >2,750KVA of robust, conditioned, highest quality AC power</li>
                                              <li >Four Liebert 600 series UPS systems (3-750KVA & 1-500KVA)</li>                
                                              <li >Redundant generator backup (5750 KW) with a 30 hour fuel reserve and extended fuel contracts</li>           
                                              <li >680 tons of robust and redundant HVAC</li>
                                              <li >Temperature maintained between 64 and 78 degrees</li>                
                                              <li >24 Liebert HVAC units for N+1 redundancy</li>           
                                              <li >Liebert rooftop glycol drycooler units for N+1 redundancy</li>
                                              <li >Floor water dams with liquid sensors</li>                
                                              <li >VESDA (Very Early Warning Smoke Detection)</li>           
                                              <li >Multi-zone sprinkler system - dry-piped, pre-action</li>
                                    </ul>
                              </div>


              </Col>
            </Row>
&nbsp;
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 9. About our Data Center</li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>
      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore9;