import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore6 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h2>Hosted Archiving Solution Overview</h2><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}

<br/>
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                   <h4 style={{color:"#002249"}}>Wild Blue Backup Hosted Archiving Solution Overview</h4><br/>
                                   
                                   Our premier hosted archiving solution enables you to reduce the cost of corporate data storage and backup, electronic discovery constraints
                                    during legal proceedings and protect critical company information. In these tough economic times, IT staffs are being asked to do more with less.
                                     Archiving as a hosted service enables your IT team to refocus their efforts on key tasks that will help generate revenue and enables employees to 
                                     seamlessly and rapidly search archived data on their own.
                        </div>

                        <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
        
                            <h4 style={{color:"#002249"}}>Is Archiving Really Necessary?</h4><br/>
                            Despite the common myth that archiving is a necessity only for public companies or financial services firms, new State and Federal regulations 
                            mandating proper email archiving practices are forcing businesses of all sizes and industries to evaluate and implement email archiving technology.
                            Unfortunately, smaller companies can't implement these requirements because they don't have the money or personnel necessary to manage such a project.
                            Our big business Wild Blue Backup hosted archiving solutions can help small businesses meet the new government regulations. 
                            It's simple to deploy, easy to use and cost effective.
                        </div>


                        <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                          <h4 style={{color:"#002249"}}>Next Generation Technology</h4><br/>
                          Our premier hosted archiving solution combines ultra-reliable storage with modern "web 2.0" technology, user interface and design methodologies

                 </div>





                          <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}>Web-Based Interface Is Easy To Use</h4><br/>
                                   
                                    Our Hosted Archiving user interface is intuitive for all skill set levels.Any user can quickly and easily search documents using the web-based interface without any training or technical knowledge.Administrators can use the web's user interface to configure the system without the need for systems engineers.
                            </div>



                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}>Unlimited Storage at No Additional Cost</h4><br/>
                                   
                                    The real cost of a hosted archiving service is often difficult to calculate since a large number of providers tack on added fees for 
                                    additional archived data beyond the basic storage limit. Our hosted archiving service has no additional fees or contracts or commitments
                                     and provides for unlimited storage of all data for as long as they are a customer.
                            </div>
                           
                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}> Immediate and Comprehensive Search</h4><br/>
                                   
                                    Some hosted archive vendors migrate your older data to offline or near-line storage systems.This adds a considerable delay to the search process due to the inability of a user to conduct an immediate search on data which is readily available. Our hosted archiving service keeps all data online and immediately accessible for searches.
                            </div>


                              <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}> Archiving for Compliance and Document Management for Data Storage Are Supported</h4><br/>
                                   
                                    Our Hosted Archiving service is designed to accommodate both compliant archiving (FRCP, SEC & HIPAA) as well as storage resource management.
                            </div>

                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                    
                                    <h4 style={{color:"#002249"}}> Email Environment Supported</h4><br/>
                                    Can be purchased with Wild Blue Backup's hosted Exchange 2010 or hosted Exchange 2007 services or as a standalone service.
                            </div>



                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                    <h4 style={{color:"#002249"}}>Other Features:</h4><br/>
                                    &nbsp;&nbsp;  Access your FULL mailbox, calendar, contacts, and public folders - not just inbox or email folders from:
                                    <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                                        <li >Setup and configuration in just minutes.</li>                
                                        <li > Replication to data centers located on the East and West coasts.</li>           
                                        <li > Export archived data to file, text, PDF or HTML.</li>
                                        <li>Help is available to migrate data.</li>
                                        <li>30 day no obligation trail</li>
                                        <li>99.999% uptime guarantee.</li>
                                        <li>24x7 server monitoring and maintenance.</li>
                                        </ul>
                              </div>


              </Col>
            </Row>
&nbsp;
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 6. Email Archive</li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore6;