import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore2 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h4 >Microsoft Exchange Server: To Outsource Or To Deploy In-House?</h4><br/>
                  <h4 > Which Is Right For You?</h4>
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                   <h4 style={{color:"#002249"}}>Enterprise Infrastructure</h4>
                   <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                      <li >Should your hardware stop working, do you have extra parts and additional hardware available to be able to quickly swap them out to limit service interruptions?</li>                
                      <li > Do you have a second connection to the internet for both your incoming and outgoing email should your T1 or DSL lines go down?</li>           
                      <li > Do you have extra power supplies available from various grids or a diesel generator backup should the power go out?</li>
                      <li> Does your building contain fingerprint readers or access card machines to ensure the security of your servers? If not, how do you prevent disgruntled employees from damaging your hardware?</li> 
                      <li>Do you have multiple antivirus engines updated hourly to ensure the latest level of protection from the #1 threat?</li>
                      <li>Do you have automatic failover measures in place - redundant servers, domain controllers - in the event of a hardware failure?</li>
                      <li>Do you have application monitoring software in place to proactively catch issues with Exchange server before they become a problem?</li>
                      <li>Do you install patches and updates as soon as they are available to prevent hackers from gaining access? Do you back up your important company data? If so, how frequently?</li>
                    </ul>
                  

                </div>

                <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Internal Staff Comparison</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                    <li >Is your office IT administrator specialized in Microsoft Exchange Server?</li>                
                    <li > Can you quickly contact your IT department in case of problems?</li>           
                    <li >ow much time can your administrator spend every day proactively checking the server & Exchange logs and monitoring performance to resolve issues before they become problems?</li>
                    <li> Do they have the experience to setup advanced features such as: RPC over HTTP, spam filtering, ActiveSync, VPN, etc.?</li> 
                   </ul>
                 </div>


                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Outside Consultants and Consulting Firm Evaluation</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li> Can they assure you of a 100% uptime guarantee?</li>                
                    <li > Should a piece of hardware fail, how soon can an outside consultant arrive on scene to solve the problem?</li>           
                    <li >Does your outside consultant ever take a vacation? How long will it take you to find someone to replace them and come up to speed on your network?</li>
                    
                   </ul>
                 </div>

                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Online Account Management Using Our Web-Based Control Panel</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li> Users, distribution lists, contacts and more can be quickly and simply added, changed, or deleted</li>                
                    <li > No programming skills necessary - anyone can administer the account. Don't let your limited IT resources use up all of their time dealing with administrative issues.</li>           
                    
                    
                   </ul>
                 </div>

                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Additional Services</h4>
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li>BlackBerry, iPhone, Treo and other popular Smartphones are automatically synchronized in real-time.</li>                
                    <li > Simple integration of other services like hosted Windows SharePoint Services into your corporate workflow.</li>           
                       <li>FREE copy of Outlook with each hosted Exchange mailbox.</li>                
                   </ul>
                 </div>






              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>    
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 2. To Outsource or Keep In-House?? </li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore2;