import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore4 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h4 >Hosted Microsoft Exchange Server Benefits</h4><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                   <h4 style={{color:"#002249"}}>Anywhere, Anytime Access</h4><br/>
                   &nbsp;&nbsp;  Access your FULL mailbox, calendar, contacts, and public folders - not just inbox or email folders from:
                   <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                      <li >Microsoft Outlook - home and work</li>                
                      <li > Any Web Browser - you can continue to use your favorite web browser</li>           
                      <li > All popular smart phones: iPhone, BlackBerry, Palm Treo and all Windows Mobile devices</li>
                      <li>Your messages, email folders, calendar, contacts, tasks and notes are fully synchronized with our 2-way wireless synchronization</li>
                       </ul>
                  

                </div>

                <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
 
                 <h4 style={{color:"#002249"}}>Get Your Tasks Accomplished</h4><br/>
                 &nbsp;&nbsp; Increase productivity:
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                    <li >Share Calendars - check free/busy time when scheduling meetings, and stay on top of your corporate schedule.</li>                
                    <li > Delegation - assistants can manage other team members' mailboxes.</li>     
                    <li>Collaborate with Public Folders - you can create and manage separate folders for various projects, teams, departments or customers. Each folder will have its own calendar, contact list, emails and files, and any user in the group will be able to access it.</li>      
                  </ul>
                 </div>


                 <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                 <h4 style={{color:"#002249"}}>Peace of Mind</h4><br/>
                 &nbsp;&nbsp; Up to 70% of business data resides in email.Temporary or permanent loss of email means lost productivity, lost customers, and lost revenue.
                 <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "upper-roman"}}><br/>
                    <li> Email Backups - we back up our data every day, closely monitor server performance and routinely test.</li>                
                    <li >Off-Site Data Storage - prevent Outlook data loss in case user's laptop is lost or stolen. You can easily recreate the entire mailbox on a new computer.</li>           
                    <li> Server Maintenance - we perform routine maintenance and install all upgrades and security patches as soon as they are made available by Microsoft.</li>
                    <li >99.9% / 100% uptime SLA (Shared/Dedicated servers)</li>
                    <li >By performing continuous intensive monitoring we are able to detect issues quickly and page a system administrator to resolve them before they grow into problems</li>
                   
                   </ul>
                 </div>

              </Col>
            </Row>
&nbsp;
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> 4. Exchange Server Benifits </li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore4;