import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class LearnMore10 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h3>Hosted Exchange Terms of Service</h3><br/>
                
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          {/* <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3> */}
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}

<br/>
                          <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                                                           
                                          <h4 style={{color:"#002249"}}>Service:</h4><br/>
                                        
                                            By visiting this Web site and/or using or purchasing goods and/or services from or through this Web site, you agree to be subject to the terms and conditions set forth herein and all applicable laws. We reserve the right to revise these terms and conditions and any product offerings or pricing at any time.<br/><br/>

                                            <br/> This offer is expressly conditioned upon your acceptance of these Terms of Service ("Terms"). Your use of or access to services provided by wildbluebackup.com constitutes your Agreement to these Terms, as in effect from time to time. This offer and your acceptance thereof represents the entire agreement between you and Wild Blue Backup, and supersedes any and all other communications, prior, contemporaneous or subsequent, and any course of performance or course of dealing, except as modified by revisions of this posting by Wild Blue Backup or by written agreement by an authorized officer of Wild Blue Backup (provided, however, that any such revised posting shall take effect prospectively from the date of the posting.) The Terms and the relationship between you and Wild Blue Backup shall be governed by and interpreted in accordance with the laws of the Commonwealth of Nevada, United States of America, without reference to its principles of conflicts of law. Wild Blue Backup reserves the right to send notices to its customers of changes to the Wild Blue Backup Privacy Statement or these Terms of Service, or of any material changes affecting their service.<br/><br/>

                                            <br/> It is your responsibility to keep Wild Blue Backup notified of any changes to your e-mail address or other contact information to assure that customers receive all such notices from Wild Blue Backup. Billing and contact information can be updated directly through the Wild Blue Backup Control Panel or by contacting Wild Blue Backup's Support department at <b>Support@wildbluebackup.com</b><br/><br/>

                                            <br/>  If your account is suspended due to non-payment, for any reason, you will be required to pay the entire balance due plus an additional 6.5% of the total balance due prior to your account being re-enabled.<br/><br/>

                                            <br/> Customers are responsible for keeping billing information up to date. Wild Blue Backup reserves the right to suspend an account if, for any reason, we are not able to process payment through the credit card or payment is outstanding. You can review and correct information through the "Edit Billing Details " link in the Control Panel.<br/><br/>

                                            <br/>  If a check received by us for payment is returned for any reason, Wild Blue Backup reserves the right to suspend or cancel service until the payment plus a $35.00 returned check fee, has been received by us. Wild Blue Backup reserves the right to accept only US Postal money orders as payment if any check is returned for any reason. We may also require that you pay by credit card for future payments.<br/><br/>
                             </div>



                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                    <h4 style={{color:"#002249"}}>Minimum Monthly Requirement:</h4><br/>
                                    Customers of our Service are required to spend a minimum of $14.99 per month. Customers that do not meet this minimum requirement will be charged an additional fee to reach this minimum requirement.
                                  
                              </div>




                              <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                 <h4 style={{color:"#002249"}}>Refund and Cancellation Policy:</h4><br/>
                                 Unless specifically noted otherwise for the service that you signed up for, you are free to cancel your service at any time.<br/>

                                  If you purchase a service that includes a 30-day trial, you are allowed to make full use of the service after signup. If you decide to cancel such service for any reason, you must send an e-mail within 30 days of your signup date to Support@wildbluebackup.com and we will reply to your email with a form in order to verify your identity; once we receive the completed form, we will cancel the service within one business day and you will not be charged any setup or recurring fees. However, if you do not cancel during this 30-day trial period, then you will be obligated to pay all fees that you have incurred from the signup date until your cancellation date.<br/><br/>

                                  If you purchase a service that includes a 30-day money-back guarantee, Wild Blue Backup will refund all monthly fees previously paid by you upon your request. The 30-day money-back guarantee refers to credit-card payments only. Due to the costs associated with processing payments made by check and Western Union, we are not able to offer the 30-day money-back guarantee for customers who utilize these payment methods.<br/>

                                  Set-up fees, fees for products not included in your service - such as domain names - and monthly charges incurred after the first 30 days are not refundable.<br/>

                                  <br/>    If you select the quarterly or annual payment option and choose to cancel your account, after the first 30 days you may be eligible for a partial refund. Wild Blue Backup will charge you the current monthly rate for the months you were a customer and refund any balance. Your refund will generally be made in the same form of payment you choose to use when paying for your service (e.g., credit card, check or Western Union).<br/>

                                  <br/> The terms and conditions, Terms of Service, product offerings and pricing are subject to change at any time, without notice.<br/>
                
                              </div>



                              <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                  <h4 style={{color:"#002249"}}>User Data</h4><br/>
                                  Pursuant to Wild Blue Backup's Privacy Statement, Wild Blue Backup may disclose to third parties certain aggregate information contained in your Registration Data or related data, provided that, such information will NOT include personally identifying information, except as specifically authorized by you or in the good faith belief that such action is reasonably necessary to comply with the law, legal process, to enforce the Terms, or under any of the other circumstances set forth in the Group Spark privacy policy, as may be amended from time to time.
                               </div>


                               <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                  <h4 style={{color:"#002249"}}>Wild Blue Backup Service and Content</h4><br/>
                                  Wild Blue Backup services may only be used for lawful purposes. Transmission or solicitation of any material that violates United States Federal, State or other laws that may apply in this jurisdiction or your local area is prohibited. This may include material that is obscene, threatening, harassing, or libelous, promotes gambling, or is in any way a violation of intellectual property laws. No material may be stored on or transmitted through any servers hosted by Wild Blue Backup that would commonly be considered "indecent" or "appealing to the prurient interest" also commonly referred to as "adult" material, nor will any links to such Web sites be tolerated within the content hosted by Wild Blue Backup.
                               </div>


                               <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                  <h4 style={{color:"#002249"}}>Spam Policy</h4><br/>
                                 
                                  Due to strict SPAM prevention policies implemented by 3rd party e-mail systems (such as AOL and Hotmail) and our mission to provide reliable e-mail service to our customers, we have a strict policy of zero-tolerance towards any e-mails sent out by a customer that may jeopardize our ability to deliver other customers e-mails to 3rd party mail systems.

                              <br/><br/>   Our e-mail hosting service is not designed for sending out large quantities of e-mail marketing messages. There are 3rd party companies that exclusively focus on delivering marketing e-mails, such as https://www.ConstantContact.com , https://www.JangoMail.com , and https://www.topica.com. Please note that we do not recommend any of these companies; rather they are listed just as examples.

                              <br/><br/> The sending or facilitation of the sending of bulk e-mail of any kind, other than verifiable, Double Opt-in email is strictly prohibited. Additionally, such Double Opt-in e-mail must be limited to a total of 200 e-mails per calendar month across all users in your account. If you need to send out a larger quantity of e-mails, then you must use a third-party e-mail marketing service such as one listed above. Double Opt-in means that when the user enters their email address on your website, they are sent an email CONFIRMING the opt-in, requiring them to click a LINK to verify that they opted in for your mailing. You may not mail to that email address unless they have confirmed the opt-in. At any time, upon Wild Blue Backup's sole discretion, you may be required to provide documented proof that all of your end-users have CONFIRMED an opt-in. This can be done by storing the IP address, a date-timestamp, and a unique ID for the confirmation. At its sole discretion, Wild Blue Backup will determine from all of the evidence whether the email recipients were from an "Double Opt-in" email list and take appropriate action.

                              <br/><br/>     Sending unsolicited commercial or bulk e-mail or any other method of distributing electronic messages to recipients who have not requested them, otherwise known as "spam" from our servers, or sending such e-mail with a Wild Blue Backup-hosted Web site listed as the contact address, or any use of Wild Blue Backup's services or systems associated in any way with such mailings is strictly forbidden. Wild Blue Backup will immediately terminate, without any warning, the account of any customer who conducts these activities. Wild Blue Backup also reserves the right to remove customers who send mass/spam news postings, as well as any accounts advertising or distributing software or services that may contribute to news or e-mail spamming. Wild Blue Backup reserves the right to report such violations to the proper government authorities, as well as Internet service providers, and Internet control organizations. To report spam, please forward the spam with all original headers to abuse@wildbluebackup.com.

                              <br/><br/> It is a violation of these Terms for you to misuse or fraudulently use credit cards, charge cards, electronic funds transfers, and/or electronic checks. A determination of such misuse or fraudulent use shall be in Wild Blue Backup's sole discretion. Further, Wild Blue Backup shall report, and you shall waive all rights to privacy, to the extent permitted by law, all such misuses and fraudulent uses as determined by Group Spark in its sole discretion to appropriate government authorities, credit reporting services, financial institutions and credit card companies.

                              <br/><br/>   It is a violation of these Terms for you to attempt to breach or to breach the security or firewall systems of Wild Blue Backup, its Internet access provider, or its Affiliates. If you become aware of such an attempt to breach or actual breach, please notify us at abuse@wildbluebackup.com. Complaints may be investigated by Wild Blue Backup but Wild Blue Backup is under no obligation to act or not act with regards to any complaint. Any action taken on a complaint will be at the sole discretion of Group Spark. Notice of attempts to breach or actual breaches of such security or firewall systems should be directed to: support@wildbluebackup.com.
                              </div>



                               <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                  <h4 style={{color:"#002249"}}>TERMINATION</h4><br/>
                                  Wild Blue Backup may terminate this agreement and your access to any or all Services at any time, with or without cause, effective immediately. Wild Blue Backup shall have no responsibility to notify any third-party providers of services, merchandise, or information, nor any responsibility for any consequences resulting from such discontinuance or lack of notification. Anyone determined by Wild Blue Backup to have violated these Terms of Service may be barred from receiving any services from Wild Blue Backup.

                               <br/> <br/>  You may terminate this Agreement at any time by way of the follow process: 1) send an email to Support@wildbluebackup.com to request a cancellation form to which we will respond with the form within one business day, and 2) fill out the cancellation form and send it back to us. Once we receive your completed cancellation form, we will cancel all Services and such date will be considered the date of termination.

                               <br/> <br/>   Termination, by us or you, does not release you of any past or current fees owed for Services rendered through the date of termination.
                               </div>



                               <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                  <h4 style={{color:"#002249"}}>LIABILITY LIMITATION</h4><br/>
                                  YOU AGREE THAT ALL ACCESS AND USE OF Wild Blue Backup AND THE CONTENT THEREOF IS AT YOUR OWN RISK. IN NO EVENT SHALL Wild Blue Backup BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS, USE OR INABILITY TO ACCESS OR USE (i) wildbluebackup.com AND/OR ITS CONTENT, (ii) ANY TOOLS OR SERVICES ON wildbluebackup.com, (iii) ANY CUSTOMER CONTENT ON WILD BLUE BACKUP'S SERVERS, OR OUT OF ANY ERRORS OR OMISSIONS IN THE CONTENT THEREOF. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THIS WEB SITE, SITE-RELATED SERVICES, PRODUCTS, AND/OR CONTENT CONTAINED WITHIN THE WEB SITE, OR WITH THE EXTENT TO WHICH SUCH WEBSITES ARE "UP" OR ARE AVAILABLE FOR ACCESS BY YOU OR OTHER USERS OF THE INTERNET, IS TO STOP USING Wild Blue Backup
                               </div>



                               <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                <h4 style={{color:"#002249"}}>WARRANTY LIMITATION</h4><br/>
                                Wild Blue Backup AND ITS CONTENT ARE PROVIDED "AS IS", WITH NO WARRANTIES WHATSOEVER. ALL REPRESENTATIONS AND/OR WARRANTIES OF ANY KIND, EITHER EXPRESSED, IMPLIED OR STATUTORY, INCLUDING, WITHOUT PARTICULAR LIMITATION TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, ARE EXPRESSLY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW. ALL CUSTOMERS ACKNOWLEDGE THAT ANY WARRANTY THAT IS PROVIDED IN CONNECTION WITH ANY PRODUCT AND/OR SERVICE AVAILABLE THROUGH Wild Blue Backup IS PROVIDED SOLELY BY THE OWNER, ADVERTISER, OR MANUFACTURER OF THAT PRODUCT AND/OR SERVICE, AND NOT BY Wild Blue Backup.
                              </div>

                              <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                                <h4 style={{color:"#002249"}}>Severability</h4><br/>
                                If any provision of these Terms should, for any reason, be held invalid or unenforceable in any respect, the remainder of these Terms shall be enforced to the full extent permitted by law. A court of competent jurisdiction is hereby empowered to modify the invalid or unenforceable provision to make it valid and enforceable.
                              </div>


                              <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                               <h4 style={{color:"#002249"}}>Waiver Of Jury Trial</h4><br/>
                                Both you and Wild Blue Backup hereby agree to waive all respective rights to a jury trial of any claim or cause of action related to or arising out of these Terms. The scope of the waiver is intended to be all encompassing of any and all disputes that may be filed in any court and that relate to the subject matter herein, including without limitation, contract claims, tort claims, breach of duty claims, and all other common law and statutory claims. You and Group Spark each acknowledge that the waiver is a material inducement for each party to enter into a business relationship, that each party has already relied on the waiver in entering into these Terms and that each will continue to rely on the waiver in their related future dealings. Each party further warrants and represents that each has had the opportunity to have legal counsel review the waiver. The waiver is irrevocable, meaning that it may not be modified either orally or in writing, and the waiver shall apply to any subsequent amendments, renewals, supplements or modifications to these Terms. In the event of litigation, these Terms may be filed as written consent to a trial by court.
                            </div>

                            <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                 
                            <h4 style={{color:"#002249"}}>Miscellaneousl</h4><br/>
                             
                                                            
                                These Terms shall bind and benefit the successors and heirs of the parties.

                                <br/> <br/>By visiting this Web site and/or using or purchasing goods and/or services from or through this Web site, you consent to the federal and state court of the Commonwealth of Nevada having jurisdiction over you.

                                                          </div>









              </Col>
            </Row>
&nbsp;
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
                  <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>10. Hosted Exchange Terms of Service</li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>
      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default LearnMore10;