import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class CaseDetails4 extends React.Component {

    render() { 
        return (
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h3 className="title">Supported Software</h3>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            <Link to={"/Case-study"}> Products</Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                    {/* page title */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
            {/*====== PAGE TITLE PART ENDS ======*/}

            {/*====== CASE DETAILS PART START ======*/}
            <div className="case-details-area pt-120 pb-115">
              <Container>
                <br />
                <h3
                  className="title"
                  style={{
                    textAlign: "center",
                    borderColor: "black",
                    borderWidth: "1",
                    marginTop: "120px" ,
                  }}
                >
                  Supported Software - Anti Virus
                </h3>
                <br />

                <Row>
                  <Col lg="12">
                    {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
                    {/* */}{" "}
                    <div className="case-details-content mt-50 pb-20">
                      <p style={{ color: "black" }}>
                        {" "}
                        The following Anti Virus Software are fully supported by
                        Wild Blue Backup
                        <br />
                        <br />
                        <ul
                          style={{
                            paddingLeft: "65px",
                            color: "#69a1bb",
                            fontSize: "22px",
                          }}
                        >
                          <li style={{ paddingLeft: "65px", fontSize: "18px" }}>
                            {" "}
                            <Link to={"/"}>1. Avast! </Link>
                          </li>
                          <li style={{ paddingLeft: "65px", fontSize: "18px" }}>
                            {" "}
                            <Link to={"/"}>2. AVG </Link>
                          </li>
                          <li style={{ paddingLeft: "65px", fontSize: "18px" }}>
                            {" "}
                            <Link to={"/"}>3. Kaspersky</Link>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </Col>
                </Row>

                {/*<Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25">
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
      <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Hosted Exchange Home </Link></li>    
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}>- <Link  to={"/"}>Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}>- For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> 


              
</Col>
            </Row> */}
              </Container>
            </div>
          </Layout>
        );
    }
}
 
export default CaseDetails4;