import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends React.Component {

  render() { 
      return (
        <React.Fragment>
          <footer
            className="footer-area footer-area-2 footer-area-1 bg_cover"
            style={{ backgroundImage: "url(assets/images/logo.png)" }}
          >
            <div className="footer-overlay">
              <Container
                className="position-relative"
                style={{ fontFamily: "montserrat" }}
              >
                <Row>
                  <Col lg="4" md="7">
                    <div className="widget-item-1 mt-30">
                      <img
                        src="/assets/images/wbblogo.gif"
                        alt=""
                        style={{ width: "176px", height: "60px" }}
                      />
                      <p
                        style={{ fontFamily: "montserrat", marginTop: "20px" }}
                      >
                        Wild Blue Backup came to be in late 2005 when a friends
                        (Steve) business was broken into and his computers were
                        stolen. Steve lost all of his bookkeeping records, all
                        of the documents and forms he need to run his business.
                        Steve did what every other small business owner would
                        do, try to recreate his accounts receivable and start
                        again from scratch all of his contracts and documents.
                        Steve struggled like this for a few months before he had
                        to throw in the towel.
                      </p>
                      {/*   <p>One thing that hasn’t changed is that we’re a small team — with most of us not working</p>*/}
                    </div>{" "}
                    {/* widget item 1 */}
                  </Col>
                  <Col lg="3" md="5" className="offset-lg-1">
                    <div className="widget-item-2 mt-30">
                      <h4 className="title">Quick Links</h4>
                      <div className="footer-list">
                        <ul>
                          <li>
                            <Link to={"/case-study"}>
                              <i className="fal fa-angle-right" /> Product
                            </Link>
                          </li>
                          <li>
                            <Link to={"/Faq"}>
                              <i className="fal fa-angle-right" /> Support
                            </Link>
                          </li>
                          <li>
                            <Link to={"/about"}>
                              <i className="fal fa-angle-right" /> About
                            </Link>
                          </li>
                          <li>
                            <Link to={"/buy"}>
                              <i className="fal fa-angle-right" /> Buy
                            </Link>
                          </li>
                          <li>
                            <Link to={"/contact"}>
                              <i className="fal fa-angle-right" />
                              Contact
                            </Link>
                          </li>
                        </ul>
                        {/*<ul>
                        <li><Link to={"details"}><i className="fal fa-angle-right" /> Details</Link></li>
                        <li><Link to={"contact"}><i className="fal fa-angle-right" /> Contact</Link></li>
                        <li><Link to={"business"}><i className="fal fa-angle-right" /> Business</Link></li>
                        <li><Link to={"affiliate"}><i className="fal fa-angle-right" /> Affiliate</Link></li>
                      </ul> */}
                      </div>
                    </div>{" "}
                    {/* widget item 2 */}
                  </Col>
                  <Col lg="4" md="6">
                    <div className="widget-item-2 widget-item-3 mt-30">
                      <h5 style={{ fontSize: "18px", color: "white" }}>
                        {" "}
                        WILD BLUE INC
                      </h5>
                      <p>
                        {" "}
                        <br /> 5500 Grossmont center,
                        <br />
                        Dr # 440-2423 LA MESA, CA
                      </p>
                      <br />
                      <br />
                     
                      <h5 style={{ fontSize: "18px", color: "white" }}>
                        Working Hours
                      </h5>
                      <ul>
                        <li>Monday - Friday: 9:00 - 17:00</li>
                        <li>Saturday and Sunday: holidays</li>
                        {/*<li>Sunday and holidays: 8:00 - 10:00</li> */}
                      </ul>
                    </div>{" "}
                    {/* widget item 3 */}
                  </Col>
                </Row>{" "}
                {/* row */}
                <Row>
                  <Col lg="12">
                    <div className="footer-copyright">
                      <p>
                        {" "}
                        Backup Software, ©1999 - 2007,{" "}
                        <span> WILD BLUE INC.</span>{" "}
                      </p>
                    </div>{" "}
                    {/* footer copyright */}
                  </Col>
                </Row>{" "}
                {/* row */}
              </Container>{" "}
              {/* container */}
            </div>
          </footer>
          {/*====== BACK TO TOP ======*/}
          <div className="back-to-top back-to-top-2">
            <a href="#backTotop">
              <i className="fas fa-arrow-up" />
            </a>
          </div>
          {/*====== BACK TO TOP ======*/}
        </React.Fragment>
      );
  }
}
 
export default Footer;