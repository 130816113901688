import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class CaseDetails3 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center" style={{fontFamily:"montserrat"}}>
                  <h3 className="title">Hosted Microsoft</h3>
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      Hosted Microsoft
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container>
          <h3 className="title" style={{textAlign:"center"}}>Hosted Microsoft Exchange Server</h3>
            <Row>
              <Col lg="12">
                {/*<div className="case-details-thumb">
                  {/*<img src="assets/images/case-details-thumb.jpg" alt="" />*
                  <div className="case-live">
                    <div className="case-live-item-area d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Sweet Client</h5>
                        <span>Rosalina D. William</span>
                        <i className="fal fa-user" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Date</h5>
                        <span>24th May 2019</span>
                        <i className="fal fa-calendar-alt" />
                      </div>
                    </div>
                    <div className="case-live-item-area mt-35 d-flex justify-content-between">
                      <div className="case-live-item">
                        <h5 className="title">Website</h5>
                        <span>www.example.com</span>
                        <i className="fal fa-globe" />
                      </div>
                      <div className="case-live-item">
                        <h5 className="title">Category</h5>
                        <span>Design, UI/UX</span>
                        <i className="fal fa-tags" />
                      </div>
                    </div>
                    <div className="case-live-btn text-center">
                      <Link  className="main-btn" to={"/"}>
                        Live Preview
                      </Link>
                    </div>
                  </div>
                </div></Row>*/}
            {/* */}    <div className="case-details-content mt-50 pb-20" style={{fontFamily:"montserrat"}}>
                  <p style={{color:"black"}}>
                  Microsoft Exchange Server has traditionally been used by very large corporations to increase
                   productivity and communications. Small businesses, on the other hand, stand to gain from its
                    use, but have not been able to afford setting it up and maintaining it. It is the dominant
                     product in the market of e-mail and collaboration software.<br/><br/>

                  Because e-mails, files, contacts, and calendars have become crucial to the day-to-day operation
                   of all businesses, the employees today want to be able to access them from anywhere and anytime.
                    Large corporations enjoy the benefits of Microsoft Exchange, but SMBs have neither the
                     infrastructure nor capital to give their employees mobile, secure and reliable communication.<br/><br/>
                </p><p>
                   <h5 style={{color:"#002249"}}> Our Hosted Exchange service includes outstanding features such as<br/></h5>
                   <ul style={{paddingLeft:"45px",color:"#69a1bb",listStyleType: "circle"}}><br/>
                      <li>Universal Mail Access: Anytime, Anywhere access to all of your e-mail folders, files, contacts and calendar via Microsoft Outlook or any browser.</li>                
                      <li>Groupware & Collaboration : share any of your e-mail folders, files, contacts and calendar with any co-worker, project, or within a department.</li>           
                      <li>Virus Scanning: Because e-mail is the most common method of spreading viruses, we include scanning of all incoming and outgoing e-mail.</li>
                      <li>Server Backup: When was the last time you backed up all of your crucial e-mail? We backup everything daily.</li> 
                  </ul>
                  </p>

                </div>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25" style={{fontFamily:"montserrat"}}>
                  <h5 className="title"style={{paddingLeft:"45px",color:"#002249"}}>Learn More -</h5><br/>
                  <p style={{color:"black",fontSize:"12px"}}>
               
                
      <ul style={{paddingLeft:"65px",color:"#69a1bb"}}>
      <li style={{paddingLeft:"65px",fontSize:"14px",color:"black"}}>1. Hosted Exchange Home</li>  
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more2"}>2. To Outsource or Keep In-House?? </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more3"}>3. Exchange Server Features </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more4"}>4. Exchange Server Benifits </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more5"}>5. Email Encryption </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more6"}>6. Email Archive </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more7"}>7. Good Mobile Messaging Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more8"}>8. For Blackberry Server </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}> <Link  to={"/learn-more9"}>9. About our Data Center </Link></li> 
                <li style={{paddingLeft:"65px",fontSize:"14px"}}><Link  to={"/learn-more10"}>10. Hosted Exchange Terms of Service </Link></li>
                <li style={{paddingLeft:"65px",fontSize:"14px",color:"red"}}> For more information Please Call us at(702) 267-6630</li>
           

      </ul>

      
     </p>
                </div>

               {/* 
                <div className="case-video-thumb mt-30">
                  <img src="assets/images/case-video-thumb.jpg" alt="" />
                  <Link  className="video-popup" to={"https://bit.ly/2VuPnrx"}>
                    <i className="fas fa-play" />
                  </Link>
                </div> */}


              
              </Col>
            </Row>
          </Container>
        </div>
        </Layout>
      );
    }
}
 
export default CaseDetails3;