import React from 'react';
import Layout from '../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

class CaseDetails5 extends React.Component {

    render() { 
        return ( 
          <Layout>
        <div className="page-title-area" style={{fontFamily:"montserrat"}}>
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title"  style={{fontSize:"36px",paddingTop:"25px"}} >Hosted Microsoft Exchange Server - Support</h2>
                  {/* <nav aria-label="breadcrumb">

                  
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link  to={"/"}>Home </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                      support
                      </li>
                    </ol>
                  </nav> */}
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {
          /*====== PAGE TITLE PART ENDS ======*/
        }

                    {
          /*====== CASE DETAILS PART START ======*/
        }
        <div className="case-details-area pt-120 pb-115" style={{fontFamily:"montserrat"}}>
          <Container><br/><br/>
          <h3 className="title">A. Setup & using your Microsoft Outlook in Exchange Server mode:</h3>
          
                  
            <Row>
              <Col lg="12">
                <div className="case-content-2 pt-40 pb-25">
                  <h5 className="title">Ensuring Your Personal Life -</h5><br/>
                  <p style={{color:"black",fontSize:"16px"}}>
    <ul style={{paddingLeft:"45px",color:"black"}}>
                <li>1.   Getting Started Guide for new customers </li>                
                <li>2.  Change Mail (MX DNS) Record for Your Domain               </li>
                <li>3.   Setup Microsoft Outlook for Exchange: </li>
                        <ul style={{paddingLeft:"30px",color:"#69a1bb"}}>
                        <li>Outlook 2000</li>                
                        <li> Outlook 2002 (XP)</li>
                        <li>Outlook 2003: Windows 2000, Windows XP, or Windows 2003 Server </li>
                        <li> Outlook 2007: Windows XP</li>
                        </ul>

                <li>4.   Upgrade to Outlook 2003 from previous version of Outlook </li>
                <li>5.   Setup Outlook 2003 in Cached mode</li> 
                <li>6.   Create new profile in Outlook 2002 (XP) or 2003</li>
                <li>7.   Speed up Microsoft Outlook access: Outlook 2000, 2002 (XP), or 2003</li> 
                <li>8.   Setup VPN Access</li>
                <li>9.   Offline mode in Outlook 2003 - enable cached mode</li> 
                <li>10.   How to use Microsoft Outlook 2000 in online and offline modes</li> 
                <li>11.   How to use Microsoft Outlook 2002 (XP) in online and offline modes</li>
                <li>12.   Import previous Contacts, Calendar and Mail folders into your new Exchange Mailbox</li>
             </ul><br/>

            </p>
            {/*---------------------------------------------------------------------------------- */}

            <h5 className="title">B. Other ways of accessing your Exchange Hosting mailbox:</h5><br/>
                  <p style={{color:"black",fontSize:"16px"}}>
    <ul style={{paddingLeft:"45px",color:"black"}}>
                <li>1.  PC: any web browser via WebMail (Outlook Web Access)</li>                
                <li>2.  BlackBerry: wirelessly sync via BlackBerry Enterprise Server              </li>
                <li>3.   Cell phone or PDA: any mini-browser</li>
                <li>4.  SmartPhone/PocketPC devices: wireless ActiveSync </li>
                <li>5.  Treo 650 : wireless ActiveSync</li> 
                <li>6.  Macintosh computer: access your Exchange mailbox from Entourage</li>
                <li>7.  POP3 access from any e-mail client</li> 
                <li>8.  IMAP4 access from any e-mail client</li>
           </ul><br/>

            </p>


 {/*---------------------------------------------------------------------------------- */}

 <h5 className="title">C. Troubleshooting Guides:</h5><br/>
                  <p style={{color:"black",fontSize:"16px"}}>
    <ul style={{paddingLeft:"45px",color:"black"}}>
                <li>1.  How to recover "disappearing" Emails from your Inbox</li>                
                <li>2.  How to disable the Outlook virus warning when doing a mail merge              </li>
                <li>3.  How To Reset The Nickname And Automatic Completion Cache</li>
                <li>4. How to recover deleted items </li>
                <li>5.  Outlook is having intermittent problems connecting to Exchange</li> 
                <li>6. Error: " Task 'Microsoft Exchange Server' reported error (0x8004010F) : 'The operation failed. An object could not be found.'"</li>
                <li>7.  Error: "4.4.7 Non-Delivery Message"</li> 
                <li>8.  Error: "Your Microsoft Exchange Server is unavailable" - port 135 is blocked</li>
           </ul><br/>

            </p>


 {/*---------------------------------------------------------------------------------- */}

 <h5 className="title">D. Tips & Tutorials on using Microsoft Outlook with your Exchange Hosting mailbox:</h5><br/>
                  <p style={{color:"black",fontSize:"16px"}}>
    <ul style={{paddingLeft:"45px",color:"black"}}>
                <li>1. Import and Export from/to a .pst (Personal Folder File) file</li>                
                <li>2.  Enable basic SPAM Filter in Microsoft Outlook 2003              </li>
                <li>3. Schedule shared Resources (Conference Rooms, vehicles, A/V Equipment)</li>
                <li>4. Creating Distribution Lists </li>
                <li>5.  Change your "From" Address on outgoing e-mails</li> 
                <li>6. Open another users folders (User must be part of your organization)</li>
                <li>7. Use rules with aliases to automatically organize incoming e-mail</li>
                <li>8. Unable to Send/Receive email due to exceeding mailbox size limit</li> 
                
           </ul><br/>

            </p>




{/*---------------------------------------------------------------------------------- */}

<h5 className="title">E. Miscellaneous:</h5><br/>
                  <p style={{color:"black",fontSize:"16px"}}>
    <ul style={{paddingLeft:"45px",color:"black"}}>
                <li>1. Info on Maximum Message Size & Number of Recipients</li>                
                <li>2.  Check your PC for viruses and spyware - free tools             </li>
                <li>3. Remove Duplicate items from Outlook - Contacts, E-mails, Notes, Tasks, Appointments</li>
                <li>4.Customer Support wants to share your desktop for troubleshooting via eBLVD </li>
                <li>5.  Daylight Savings Time Changes for 2007</li> 
               
           </ul><br/>

            </p>
<br/><br/>
<h5 className="title" style={{textAlign:"center",color:"red"}}>Still have a problem after going through the Articles below? Fill out our Support request form here.</h5>


                </div>

              </Col>
            </Row>
          </Container>
        </div>




        </Layout>
      );
    }
}
 
export default CaseDetails5;