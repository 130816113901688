import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col ,Card} from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { CardBody } from 'react-bootstrap/Card';

class LatestNewsPart extends React.Component {

    render() { 
        return ( 
          <div className="latest-news-area gray-bg">
            <Container>
              <Row className="justify-content-center">
                <Col lg="6">
                  <div className="section-title text-center">
                    <h2>Wild Blue Backup</h2><br/>
                   {/* <h2 className="title">Buy Product</h2>*/} 
                  </div>
                  {/* sction title */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}

            



            <div className="letast-news-grid white-bg  ml-40 mr-40" style={{backgroundColor:'#F5FAFE'}}>
              <Container >
                <Row>
                  <Col lg="12">
                    <div className="latest-news">
                      <Row>
                      
                      
                       {/* 1 */}
                        <div
                          className="col-lg-12 col-md-6 wow slideInUp"
                          data-wow-duration=".5s"
                          data-wow-delay="0s"
                        >
                            <Card style={{borderColor:'#3159D8',padding:'15px',borderRadius: '25px 25px'}}>
                          <div className="letest-news-item mt-30" style={{paddingLeft:'15px'}}>
                           {/*  <span>By Admin, 05 Aug 2019</span>*/}
                            <h4 className="title"> Wild Blue Backup Client $150 Per License After Two Weeks Trial  </h4>  
                            
                          </div>
                          <a href={"https://www.paypal.com/webapps/shoppingcart?flowlogging_id=940644eb577fb&mfid=1609923022558_940644eb577fb#/checkout/openButton"}>
                            <button style={{marginLeft:"20px",marginTop:"27px" ,borderRadius: "15px 15px",borderColor:"#3159D8",color:"black", width:"160px",height:"70px"}}>
                            TRY NOW <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} /> 
                            </button>
                          </a><br/>
                          </Card>
                       
                        </div>
                     
                     
                     
                      {/* 2
                     
                        <div
                          className="col-lg-4 col-md-6 wow slideInUp"
                          data-wow-duration="1s"
                          data-wow-delay="0s"
                        >
                           <Card style={{borderColor:'#3159D8',padding:'15px',borderRadius: '25px 25px'}}>
                          <div className="letest-news-item mt-30" style={{paddingLeft:'15px'}} >
                           
                            <h4 className="title"> Wild Blue Backup Client Software CD </h4>
                          </div>
                             <a href={"https://www.paypal.com/webapps/shoppingcart?flowlogging_id=ba23162a581b6&mfid=1609924331477_ba23162a581b6#/checkout/openButton"}>
                            <button style={{ marginLeft:'20px',marginTop:'20px',paddingBottom:'20px;' ,borderRadius: "15px 15px",borderColor:"#3159D8",color:"black", width:"160px",height:"70px"}}>
                            BUY NOW <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} /> 
                            </button>
                          </a><br/>
                          </Card>
                         
                          lesast news item
                        </div>
                       */}
                      
                      
                      
                      
                        {/* 3 
                      
                            <div
                              className="col-lg-4 col-md-6 wow slideInUp"
                              data-wow-duration="1.5s"
                              data-wow-delay="0s"
                            >
                              <Card style={{borderColor:'#3159D8',padding:'15px',borderRadius: '25px 25px'}}>
                                 
                                <div className="letest-news-item mt-30" style={{paddingLeft:'15px'}}>
                                    <h4 className="title">Hosted Exhchange User Setup<span style={{fontSize:"14px"}}> (One Time Charge Per User)</span></h4>
                                  </div>

                                  <a href={"https://www.paypal.com/webapps/shoppingcart?flowlogging_id=55fce537b34b9&mfid=1609924454121_55fce537b34b9#/checkout/openButton"}>
                                      <button style={{ marginLeft:'20px',borderRadius: "15px 15px",borderColor:"#3159D8",color:"black", width:"160px",height:"70px"}}>
                                      BUY NOW <FontAwesomeIcon icon={faLongArrowAltRight} className={`pl-1`} /> 
                                      </button>
                                </a><br/>
                               
                           lesast news item 
                            </div> */}
                            
                            


                      </Row>
                    </div>
                    {/* latest news */}
                  </Col>
                </Row>
                {/* row */}
              </Container>
              {/* container */}
            </div>
          </div>
         );
    }
}
 
export default LatestNewsPart;