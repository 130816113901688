import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ToastContainer } from "react-toastify";
/* import Index1 from './pages/home/Index';
import Index2 from './pages/home/Index-2';
import Index3 from './pages/home/Index-3'; */
import Index4 from "./pages/home/Index-4";
import AboutPage from "./pages/about/About";
import ContactPage from "./pages/Contact";
import ServicesPage from "./pages/service/Index";
import ServicesDetails from "./pages/service/Details";
import CaseStudy from "./pages/Case-study";
import CaseDetails from "./pages/Case-details";
import CustomerSupport from "./pages/CustomerSupport";
import FaqPage from "./pages/Faq";
import PricingPage from "./pages/Pricing";
import ShopPage from "./pages/Shop";
import ShopDetailsPage from "./pages/Shop-details";
import ProductPage from "./pages/product/Index";
import TeamPage from "./pages/Team";
import TeamDetailsPage from "./pages/Team-details";
import BlogStandard from "./pages/blog/Blog-standard";
import BlogGrid from "./pages/blog/Blog-grid";
import BlogDetails from "./pages/blog/Blog-details";
import BlogDetails2 from "./pages/blog/Blog-details2";
import ProductDetailPage from "./pages/product-details/Index";
import NoMatch from "./pages/Error";
import Preloader from "./common/preloader";
import CaseDetails2 from "./pages/Case-details2";
import CaseDetails3 from "./pages/Case-details3";
import CaseDetails4 from "./pages/Case-details4";
import CaseDetails5 from "./pages/Case-details5";
import buy from "./pages/buy";
import LearnMore2 from "./pages/Learn-more2";
import LearnMore3 from "./pages/Learn-more3";
import LearnMore4 from "./pages/Learn-more4";
import LearnMore5 from "./pages/Learn-more5";
import LearnMore6 from "./pages/Learn-more6";
import LearnMore7 from "./pages/Learn-more7";
import LearnMore8 from "./pages/Learn-more8";
import LearnMore9 from "./pages/Learn-more9";
import LearnMore10 from "./pages/Learn-more10";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Preloader />
        <Switch>
          {/* 				  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Index1}/>
				  <Route path={`${process.env.PUBLIC_URL}/index`} component={Index1}/>
				  <Route path={`${process.env.PUBLIC_URL}/index2`} component={Index2}/>
				  <Route path={`${process.env.PUBLIC_URL}/index3`} component={Index3}/> */}
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Index4} />

          <Route
            path={`${process.env.PUBLIC_URL}/about`}
            component={AboutPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/services`}
            component={ServicesPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/services-details`}
            component={ServicesDetails}
          />

          <Route path={`${process.env.PUBLIC_URL}/buy`} component={buy} />
          <Route
            path={`${process.env.PUBLIC_URL}/case-study`}
            component={CaseStudy}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/case-details`}
            component={CaseDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/case-details-2`}
            component={CaseDetails2}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/case-details-3`}
            component={CaseDetails3}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/case-details-4`}
            component={CaseDetails4}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/case-details-5`}
            component={CaseDetails5}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more2`}
            component={LearnMore2}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more3`}
            component={LearnMore3}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more4`}
            component={LearnMore4}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more5`}
            component={LearnMore5}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more6`}
            component={LearnMore6}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more7`}
            component={LearnMore7}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more8`}
            component={LearnMore8}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more9`}
            component={LearnMore9}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/Learn-more10`}
            component={LearnMore10}
          />
          <Route path={`${process.env.PUBLIC_URL}/faq`} component={FaqPage} />
          <Route
            path={`${process.env.PUBLIC_URL}/pricing`}
            component={PricingPage}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/products`}
            component={ProductPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/products-details`}
            component={ProductDetailPage}
          />
          <Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPage} />

          <Route
            path={`${process.env.PUBLIC_URL}/shop-details`}
            component={ShopDetailsPage}
          />
          <Route path={`${process.env.PUBLIC_URL}/team`} component={TeamPage} />
          <Route
            path={`${process.env.PUBLIC_URL}/team-details`}
            component={TeamDetailsPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog-standard`}
            component={BlogStandard}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog-grid`}
            component={BlogGrid}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/blog-details2`}
            component={BlogDetails2}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/customer-support`}
            component={CustomerSupport}
          />

          <Route
            path={`${process.env.PUBLIC_URL}/contact`}
            component={ContactPage}
          />

          <Route component={NoMatch} />
        </Switch>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

export default App;
