import React from 'react';
import Slider from "react-slick";
import bannerImg from '../../../assets/images/banner-bg-3.jpg';
import { Container, Row, Col } from 'react-bootstrap';

class Banner extends React.Component {

  render() { 
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false
    };

    return ( 
      <React.Fragment>
      <div className="banner-area-3 bg_cover" style={{backgroundImage: `url(${process.env.PUBLIC_URL}'/'${bannerImg})`}}>
        <div className="banner-overlay">
          <img className="item-1" src="assets/images/banner-shape-1.png" alt="" />
          <img className="item-2" src="assets/images/banner-shape-2.png" alt="" />
          <Container>
            <Row className="align-items-center">
              <Col lg="12">
                <div className="banner-content text-center">
      {/*           <p>If I lose My Data Today .... Will I Still Be In Business Tomorrow?<br/>
                                       If My Computer Dies .... Can I Get My Data Back?<br/>
                                       Can I Buy Protection For My Data?</p>
                  <h4  style={{color:"white"}}>"With Wild Blue Backup,The Answers Are YES!"</h4><br/><br/><br/>*/}

                  <span style={{fontFamily:"montserrat"}}>Get Quality Products &amp; Softwares</span>
                  <h1  style={{fontFamily:"montserrat", color:"white",marginTop:"12px"}}>Wild Blue Backup Solutions</h1><br/>
                    <p style={{fontFamily:"montserrat",marginTop:"10px"}}>A complete Backup &amp; Disaster Recovery solution for Virtual, Physical &amp; SaaS Applications</p>
                </div> 
              </Col>
            </Row> 
          </Container> 
        </div>
      </div>

     {/* <Slider  className="brand-area brand-active" {...settings}>
      <div className="brand-item">
        <img src="assets/images/back-up.png" alt="" />
      </div>
       brand item 
      <div className="brand-item">
        <img src="assets/images/brand-2.png" alt="" />
      </div>
      
      <div className="brand-item">
        <img src="assets/images/brand-5.png" alt="" />
      </div>
      
      <div className="brand-item">
        <img src="assets/images/brand-4.png" alt="" />
      </div>
      
      <div className="brand-item">
        <img src="assets/images/brand-5.png" alt="" />
      </div>
     
      <div className="brand-item">
        <img src="assets/images/brand-4.png" alt="" />
      </div>
     
      <div className="brand-item">
        <img src="assets/images/brand-1.png" alt="" />
      </div>
      
      <div className="brand-item">
        <img src="assets/images/brand-2.png" alt="" />
      </div>
     
      </Slider>*/}
    </React.Fragment>
    );
  }
}
 
export default Banner;