import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalVideo from 'react-modal-video'
import { Container, Row, Col } from 'react-bootstrap';

class FeaturesPart extends React.Component {



  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal = event => {
    this.setState({isOpen: true});
    event.preventDefault();
  }

  formHandle = event => {
    toast.success("Thank you for subscrive us!");
    event.preventDefault();
  }


    render() { 
        return (
          <div className="features-area pt-120 pb-120">
            <Container>
              <ModalVideo
                channel="youtube"
                isOpen={this.state.isOpen}
                videoId="TdSA7gkVYU0"
                onClose={() => this.setState({ isOpen: false })}
              />

              <Row>
                <Col lg="12">
                  <div className="features-item">
                    <strong>Our Company</strong>
                    <h2 style={{ fontFamily: "montserrat" }}>
                      Wild Blue Backup
                    </h2>
                    <span style={{ fontFamily: "montserrat", marginTop:"10px" }}>
                      Our knowledge of computers will help us
                    </span>
                    <p style={{ fontFamily: "montserrat" }}>
                      Wild Blue Backup came to be in late 2005 when a friends
                      (Steve) business was broken into and his computers were
                      stolen. Steve lost all of his bookkeeping records, all of
                      the documents and forms he need to run his business. Steve
                      did what every other small business owner would do, try to
                      recreate his accounts receivable and start again from
                      scratch all of his contracts and documents. Steve
                      struggled like this for a few months before he had to
                      throw in the towel.
                    </p>
                    <ul>
                      <li>
                        <Link
                          className="main-btn wow fadeInUp"
                          to={"about"}
                          data-wow-duration=".5s"
                          data-wow-delay=".4s"
                        >
                          Learn More
                        </Link>
                      </li>
                      {/*<li>
                        <Link   onClick={this.openModal}
                          className="main-btn main-btn-2 wow fadeInUp"
                          to={"/"}
                          data-wow-duration="1s"
                          data-wow-delay=".6s"
                        >
                          
                          <i className="fal fa-video" />
                          Learn More
                        </Link>
                      </li>*/}
                    </ul>
                    <img
                      className="d-none d-lg-block"
                      src="assets/images/backup.jpg"
                      style={{ height: "300px;" }}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg="6" md="6">
                  <div className="single-features mt-30">
                    <img
                      src="assets/images/services-1.png"
                      alt=""
                      style={{ alignItems: "center" }}
                    />
                    <h4 className="title" style={{ fontFamily: "montserrat" }}>
                      Product 1
                    </h4>
                    <p style={{ fontFamily: "montserrat", color: "black" }}>
                      Wild Blue Backup Client $150 Per License
                    </p>
                    <i className="fal fa-angle-right" />{" "}
                    <a
                      href={
                        "https://www.paypal.com/webapps/shoppingcart?flowlogging_id=940644eb577fb&mfid=1609923022558_940644eb577fb#/checkout/openButton"
                      }
                    >
                      {" "}
                      BUY NOW
                    </a>
                  </div>
                </Col>
                <Col lg="6" md="6" style={{ fontFamily: "montserrat" }}>
                  <div className="single-features mt-30">
                    <img src="assets/images/services-2.png" alt="" />
                    <h4 className="title">Product 2</h4>
                    <p style={{ fontFamily: "montserrat", color: "black" }}>
                      Wild Blue Backup Client Software CD
                    </p>
                    <i className="fal fa-angle-right" />{" "}
                    <a
                      href={
                        "https://www.paypal.com/webapps/shoppingcart?flowlogging_id=ba23162a581b6&mfid=1609924331477_ba23162a581b6#/checkout/openButton"
                      }
                    >
                      BUY NOW
                    </a>
                  </div>
                </Col>
               

                {/*<Col lg="4" md="6">
                  <div className="features-sub mt-30">
                    <h5 className="title">Subscribe Now</h5>
                    <p>
                      Many aspects of computing and technology and the term is more
                      recognizable than before.
                    </p>
                    <form action="index" onSubmit={this.formHandle}>
                      <div className="input-box">
                        <input type="text" placeholder="Enter your email...." />
                        <button type="submit" className="main-btn">Subscribe Now</button>
                      </div>
                    </form>
                  </div>
                </Col> */}
              </Row>
            </Container>
          </div>
        );
    }
}
 
export default FeaturesPart;